import { FineType } from '../../../../../../../_types/enums';
import { EEntity } from '../../../../../../../services/People/types';

export const FineTypes: {
	value: FineType;
	label: string;
	allowed_entities: EEntity[];
}[] = [
	{
		value: FineType.yellow_card,
		label: 'Ammonizione',
		allowed_entities: [
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
	{
		value: FineType.double_yellow_card,
		label: 'Doppia ammonizione',
		allowed_entities: [
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
	{
		value: FineType.red_card,
		label: 'Espulsione',
		allowed_entities: [
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
	{
		value: FineType.suspension_no_red_card,
		label: 'Squalifica senza espulsione',
		allowed_entities: [
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
	{
		value: FineType.fine,
		label: 'Ammenda',
		allowed_entities: [
			'CLUB',
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
	{
		value: FineType.inhibition_with_expulsion,
		label: 'Inibizione con espulsione',
		allowed_entities: [
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
	{
		value: FineType.inhibition_without_expulsion,
		label: 'Inibizione senza espulsione',
		allowed_entities: [
			'GIOCATORE',
			'ALLENATORE',
			'DIRIGENTE',
			'OPERATORE_SANITARIO',
			'PREPARATORE_ATLETICO',
			'COLLABORATORE',
		],
	},
];

export const getFineTypes = (entity: EEntity): { value: FineType; label: string }[] => {
	return FineTypes.filter(t => t.allowed_entities.includes(entity));
};

export const getFineLabels = (): { value: string; label: string }[] => {
	return FineLabels.map(t => ({
		value: t.toUpperCase(),
		label: t,
	}));
};

export const FineLabels = [
	'Ammonizione',
	'Ammenda',
	'Ammenda con diffida',
	'Gare da disputare con uno o più settori privi di spettatori',
	'Gare da disputare a porte chiuse',
	'Squalifica del campo',
	'Ammenda e squalifica del campo',
	'Sospensione cautelare del campo',
	'Perdita della gara',
	"Retrocessione all'ultimo posto in classifica",
	'Penalizzazione punti in classifica',
	'Esclusione dal campionato',
];
