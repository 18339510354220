import { ErrorMessage, useField } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';
import { AsyncSelect } from '.';

type Props = {
	/**
	 * Function to load options.
	 */
	loadOptions: (search?: string) => Promise<any[]>;

	/**
	 * Name of the field (same of the form value).
	 */
	name: string;

	/**
	 * Placeholder of the field.
	 */
	placeholder?: string;

	/**
	 * Label of the field.
	 */
	label?: string;

	/**
	 * Define if the field is disabled.
	 */
	disabled?: boolean;

	/**
	 * Callback function to handle the change event.
	 */
	onChange?: (item: any) => void;

	key?: string;
};

const Wrapper = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	label {
		font-size: var(--s-size);
		display: block;
		color: var(--gray-light-color);
	}

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}

		.select__control {
			border-color: var(--danger-color);
		}
	}
`;

const Component = ({ onChange, ...props }: Props) => {
	const [field, meta, helpers] = useField(props);

	const [valueLabel, setValueLabel] = useState<string>();

	return (
		<Wrapper className={`${meta.touched && meta.error ? 'error' : ''}`}>
			{props.label && <label htmlFor={props.name}>{props.label}</label>}
			<AsyncSelect
				key={props.key}
				valueLabel={valueLabel}
				loadOptions={props.loadOptions}
				onBlur={field.onBlur}
				onFocus={() => {
					helpers.setTouched(true);
				}}
				onChange={item => {
					onChange?.(item);
					helpers.setValue(item?.value);
					setValueLabel(item?.label);
				}}
				isClearable
			/>
			<ErrorMessage name={props.name} component="div" className="form-field-error" />
		</Wrapper>
	);
};

export default Component;
