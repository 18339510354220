import { SyncClubView } from './view';
import { useState } from 'react';
import { MatchesService } from '../../../services';
import Toast from '../../../components/Toast';
import { Organization, useOrganizations } from '../../../providers/OrganizationsProvider';

const SyncClubContainer = () => {
	const [isSyncing, setIsSyncing] = useState<boolean>(false);
	const { organizations: clubs } = useOrganizations();
	const [filteredClubs, setFilteredClubs] = useState<Organization[]>([]);

	const onSearch = (value: string | undefined) => {
		const normalizeString = (s: string) => s.toLowerCase().trim();
		if (value)
			setFilteredClubs(
				clubs.filter(club => normalizeString(club.name).includes(normalizeString(value)))
			);
		else setFilteredClubs([]);
	};

	const onSyncClub = async (organizationId: string) => {
		setIsSyncing(true);
		try {
			await MatchesService.syncSingleClub({
				season: '2223',
				organizationId,
			});
			Toast.success('Tesserati sincronizzati');
		} catch (e) {
			Toast.error('Errore nella sincronizzazione');
		} finally {
			setIsSyncing(false);
		}
	};

	return (
		<SyncClubView
			organizations={filteredClubs.map(c => ({ id: c.organizationId, name: c.name }))}
			onSearch={onSearch}
			onSyncClub={onSyncClub}
			isSyncing={isSyncing}
		/>
	);
};
export { SyncClubContainer };
