import { Heading } from '@gle/base-ui.typography.heading';
import styled from 'styled-components';
import { SyncMatchesContainer } from './sync-matches/container';
import { SyncClubContainer } from './sync-club/container';

const SyncPage = () => {
	return (
		<div className={'flex flex-column gap-40'}>
			<Heading level={2}>{'Sincronizzazione dati'}</Heading>
			<SyncMatchesContainer />
			<div style={{ borderTop: '1px solid lightgray' }} />
			<SyncClubContainer />
		</div>
	);
};

export { SyncPage };

export const StyledIcon = styled.div`
	i {
		animation-name: rotation;
		font-size: 20px;

		&.animation {
			animation-duration: 1s;
			animation-iteration-count: infinite;
		}
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;
