import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ApiQueryParams } from '../../services';
import { GraphQLDataProviderProps } from '../types';
import { GraphQLDataContext } from './Context';

const PERSISTED_FILTER_KEY = 'gql-filters';

const Provider = (props: GraphQLDataProviderProps) => {
	const limit: number = props.limit || 15;
	const [skip, setSkip] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const [filters, setFilters] = useState<any>();
	const [search, setSearch] = useState<string | undefined>();
	const [sort, setSort] = useState<string | undefined>(undefined);

	const [getData, { loading, error, data }] = useLazyQuery<any>(props.query, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		console.log(props.preSelectedFilters);
		props.preSelectedFilters && loadData(props.preSelectedFilters);
	}, []);

	const onSort = (value: string | undefined) => {
		if (_.isEqual(sort, value)) return;
		loadData({
			sort: value,
		});
		setSort(value);
	};

	const onFilter = (value: any) => {
		if (_.isEqual(filters, value)) return;

		let merged = { ...filters, ...value };
		loadData({ filters: merged });
		setFilters(merged);
	};

	const onSearch = (value: string | undefined) => {
		if (_.isEqual(search, value)) return;
		loadData({ search: value });
		setSearch(value);
	};

	const refreshPaginationAndData = (offset: number) => {
		loadData({ skip: offset });
		setSkip(offset);
		setPage(offset ? offset / limit + 1 : 1);
	};

	const onPrevPage = () => {
		refreshPaginationAndData(skip - limit);
	};

	const onNextPage = () => {
		refreshPaginationAndData(skip + limit);
	};

	const onChoosePage = (newPage: number) => {
		refreshPaginationAndData((newPage - 1) * limit);
		setPage(newPage);
	};

	/**
	 * Add default values from state if params are not set.
	 *
	 * @param params
	 * @returns
	 */
	const enrichParameters = (params: ApiQueryParams): ApiQueryParams => {
		if (!params.hasOwnProperty('skip')) params.skip = skip;
		if (!params.hasOwnProperty('limit')) params.limit = limit;

		if (!params.hasOwnProperty('sort')) params.sort = sort;
		if (!params.hasOwnProperty('filters')) params.filters = filters;

		if (!params.hasOwnProperty('search')) params.search = search;

		return params;
	};

	const loadData = async (params: ApiQueryParams = {}) => {
		params = enrichParameters(params);

		getData({
			variables: {
				filters: { ...params.filters, ...props.fixedFilters },
				opts: {
					sort: params.sort || props.defaultSort,
					skip: params.skip,
					limit: params.limit,
				},
			},
		});
	};

	useEffect(() => {
		if (data) {
			setHasMore(!(data[Object.keys(data)[0]].length < limit));
		}
	}, [data]);

	return (
		<GraphQLDataContext.Provider
			value={{
				data,
				loading,
				filters,
				onFilter,
				sort,
				onSort,
				search,
				onSearch,
				error,
				loadData,
				pagination: {
					page,
					canNextPage: hasMore,
					canPrevPage: skip > 0,
					onNextPage,
					onPrevPage,
					onChoosePage,
				},
			}}>
			{props.children}
		</GraphQLDataContext.Provider>
	);
};

export default Provider;
