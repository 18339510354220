import { ERole } from '@golee/gle-types';
import { PeopleService } from './People';

export * from './Documents';
export * from './Guardian';
export * from './Matches';
export * from './PdfKompiler';
export * from './People';
export * from './Pepa';
export * from './types';

// TODO: move to a separate file
export const loadProfilesOptions = async (
	role: ERole,
	search?: string
): Promise<{ value: string; label: string }[]> => {
	try {
		const response = await PeopleService.getProfiles({
			filters: { role, search },
		});

		return response.map(({ _id, name, surname }: any) => ({
			value: _id,
			label: name.concat(' ', surname || ''),
		}));
	} catch (err) {
		console.error(err);
		return [];
	}
};
