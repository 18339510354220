import { Text } from '@gle/base-ui.typography.text';
import { TObjMatch } from '@golee/gle-types';
import { CheckBox } from '../../../components/Inputs/CheckBox';
import { Column } from '../../../components/Table/Table';

export const columns = (
	selectedMatches: TObjMatch[],
	onSelectAll: () => void,
	isSelectAllActive: boolean
): Column<any>[] => {
	const cols: Column<any>[] = [
		{
			key: 'is_selected',
			name: (
				<div className={'center-cell'}>
					<CheckBox
						name={''}
						label={''}
						render={'square'}
						onChange={onSelectAll}
						value={isSelectAllActive}
					/>
				</div>
			),
			maxWidth: 50,
			formatter: ({ row }) => {
				const isMatchSelected = !!selectedMatches.find((m: any) => m.id === row.id);
				return (
					<div className={'center-cell'}>
						<CheckBox
							name={''}
							label={''}
							render={'square'}
							onChange={() => {}}
							value={isMatchSelected}
						/>
					</div>
				);
			},
		},
		{
			key: 'home.club.name',
			name: 'Casa',
			minWidth: 300,
			cellClass: 'flex items-center gap-10',
			resizable: true,
			formatter: ({ row }) => {
				return (
					<>
						{row['home.club.logo'] && (
							<img
								src={row['home.club.logo']}
								alt={row['home.club.name']}
								style={{ width: 18 }}
							/>
						)}

						<Text>{row['home.club.name']}</Text>
					</>
				);
			},
		},
		{
			key: 'away.club.name',
			name: 'Ospite',
			minWidth: 300,
			cellClass: 'flex items-center gap-10',
			resizable: true,
			formatter: ({ row }) => {
				return (
					<>
						{row['away.club.logo'] && (
							<img
								src={row['away.club.logo']}
								alt={row['away.club.name']}
								style={{ width: 18 }}
							/>
						)}

						<Text>{row['away.club.name']}</Text>
					</>
				);
			},
		},
		{
			key: 'result',
			name: 'Ris.',
			maxWidth: 50,
			formatter: ({ row }) => {
				return (
					<>
						{row['result.home']}-{row['result.away']}
					</>
				);
			},
		},
		{
			key: 'date',
			name: 'Data e ora',
			resizable: true,
			width: 200,
			formatter: ({ row }) => {
				return (
					<>
						{row.date} alle {row.time}
					</>
				);
			},
		},
	];

	return cols;
};
