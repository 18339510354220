import { EPath } from '../../router/types/path.enum';
import { EScopes } from '../../router/types/scopes.enum';
import routes from './../../router/routes';

export class PathUrl {
	path: string;
	anonymized_path: string;
	scope: EScopes;
	area?: Area;

	constructor(path: string) {
		this.path = path.replace(/^\//, '');
		this.anonymized_path = anonymizeFromUrl(this.path);
		this.scope = getScope(this.anonymized_path);
		this.scope && (this.area = getArea(this.scope));
	}

	public getItemID(): string | undefined {
		return this.path
			.split('/')
			.reverse()
			.find(_ => {
				return isObjectID(_);
			});
	}

	public static factory(): PathUrl {
		return new PathUrl(window.location.pathname);
	}

	public static buildPath(path: EPath, values: any): string {
		return `/${path
			.split('/')
			.map(chunk => (chunk[0] === ':' ? values[chunk.substr(1)] : chunk))
			.join('/')}`;
	}

	public static anonymizeFromPath = (path: EPath): string => {
		return path
			.split('/')
			.map(x => x.replace(/:.*/, '*'))
			.join('/');
	};
}

const isNumber = (str: string) => new RegExp(/^\d+$/).test(str);
const isObjectID = (str: string) => new RegExp(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i).test(str);

const anonymizeFromUrl = (url: string): string => {
	return url
		.replace(/#.*/, '')
		.split('/')
		.map(_ => {
			if (isNumber(_) || isObjectID(_)) _ = '*';
			return _;
		})
		.join('/');
};

const removeSlashCharacter = (path: string): string => {
	return path.split('/').join('');
};

const getScope = (anonymized_path: string): EScopes => {
	const scope = routes.find(
		route =>
			removeSlashCharacter(PathUrl.anonymizeFromPath(route.path)) ===
			removeSlashCharacter(anonymized_path)
	)?.scope;
	return scope || EScopes.statements;
};

const getArea = (scope: EScopes): any => {
	const areas_keys: string[] = Object.keys(Areas);
	return areas_keys.find((area: string) =>
		Areas[area].find((area_scope: EScopes) => area_scope === scope)
	);
};

export enum Area {
	matches = 'matches',
	statements = 'statements',
}

export const Areas: any = {
	matches: [EScopes.matches],
	statements: [EScopes.statements],
};
