import { Person } from '../_types';
import { useState } from 'react';
import service, { PeopleFilters } from '../services/Matches/service';

export const usePeople = () => {
	const [people, setPeople] = useState<Person[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSearch = ({
		searchName,
		searchSurname,
		organizationId,
	}: {
		searchName?: string;
		searchSurname?: string;
		organizationId?: string;
	}) => {
		loadData({
			searchName,
			searchSurname,
			organizationId,
		});
	};

	const loadData = async (filters: PeopleFilters) => {
		setIsLoading(true);
		setPeople(await service.getPeople(filters));
		setIsLoading(false);
	};

	return {
		people,
		isLoading,
		onSearch,
	};
};
