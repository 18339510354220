import { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { CreatableFormProps } from '../Forms/types';
import { Modal } from '../Modal/Modal';

type Props = {
	/**
	 * Value of the select field.
	 */
	value?: any;

	/**
	 * Placeholder of the select field.
	 */
	placeholder?: string;

	/**
	 * Creatable form component.
	 * */
	createForm: React.ComponentType<CreatableFormProps<any>>;

	/**
	 * Title of the create modal.
	 */
	createModalTitle?: string;

	/**
	 * Function to load options.
	 */
	loadOptions: (search?: string) => Promise<any[]>;

	/**
	 * On change callback.
	 */
	onChange: (value: any) => void;
};

const Component = ({ createForm, ...props }: Props) => {
	const [value, setValue] = useState<any>(props.value);
	const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
	const [elemToCreate, setElemToCreate] = useState<any>();

	const onCreate = (value: any) => {
		setElemToCreate(value);
		setIsCreateModalVisible(true);
	};

	const onCreateCompleted = (value: any) => {
		setIsCreateModalVisible(false);
		let option = {
			label: value.name.concat(' ', value.surname || ''),
			value: value._id,
		};
		setValue(option);
		props.onChange(option);
	};

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	const CreateForm = createForm;

	return (
		<>
			<AsyncCreatableSelect
				className={'rdg-text-editor select-custom'}
				styles={{
					container: provided => ({
						...provided,
						width: 'max-content',
						minWidth: '100%',
					}),
					control: provided => ({
						...provided,
						borderColor: 'var(--gray-lightest-color)',
					}),
					input: provided => ({
						...provided,
						fontSize: 'var(--m-size)',
					}),
					placeholder: provided => ({
						...provided,
						fontSize: 'var(--m-size)',
					}),
					option: provided => ({
						...provided,
						fontSize: 'var(--m-size)',
					}),
					valueContainer: provided => ({
						...provided,
						fontSize: 'var(--m-size)',
					}),
				}}
				cacheOptions
				defaultOptions
				loadOptions={props.loadOptions}
				value={value}
				isClearable
				placeholder={props.placeholder}
				onCreateOption={onCreate}
				onChange={props.onChange}
			/>

			{isCreateModalVisible && (
				<Modal
					closeOnClickOutside={false}
					onClose={() => setIsCreateModalVisible(false)}
					title={props.createModalTitle || 'Crea nuovo'}>
					<CreateForm
						name={elemToCreate}
						onCreateCompleted={onCreateCompleted}
						onCancel={() => {
							setIsCreateModalVisible(false);
						}}
					/>
				</Modal>
			)}
		</>
	);
};

export default Component;
