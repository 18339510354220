import { ERole } from '@golee/gle-types';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { Conan, ConanInterceptor } from '../../modules/@tracking';
import {
	DocumentsInterceptor,
	MatchesInterceptor,
	PeopleInterceptor,
	PepaInterceptor,
} from '../../services';
import { GuardianInterceptor, GuardianService } from '../../services';
import { ACTIONS as AUTH_ACTIONS } from './types';

const allowedRoles = [ERole.judge, ERole.referee, ERole.league_delegate, ERole.federation_delegate];

const interceptors = [
	GuardianInterceptor,
	MatchesInterceptor,
	PeopleInterceptor,
	ConanInterceptor,
	DocumentsInterceptor,
	PepaInterceptor,
];

export function* afterAuth0Login() {
	yield takeEvery(AUTH_ACTIONS.AFTER_AUTH0_LOGIN, function* (action: any) {
		try {
			var { georgian_token, token, user } = action.payload;

			if (georgian_token && token) {
				// @ts-ignore
				const resp_userperm: any = yield GuardianService.getUserPermissions(user.sub);

				if (resp_userperm.status === 200) {
					const userpermission = resp_userperm.data
						? resp_userperm.data.find((userperm: any) =>
								allowedRoles.includes(userperm.role)
						  )
						: null;

					// ------------------------------------------------
					// Check ruoli
					// ------------------------------------------------
					if (!userpermission || !allowedRoles.includes(userpermission.role)) {
						throw new Error(`Role not allowed to this client.`);
					}

					yield put({
						type: AUTH_ACTIONS.SETPERMISSIONFEATURES,
						payload: userpermission,
					});

					user = {
						...user,
						role: {
							role_id: userpermission.role_id,
							role: userpermission.role,
							limitations: userpermission.limitations,
						},
					};

					// Setto headers
					const setGoleeHeaderToAxios = (listAxios: any) => {
						listAxios.forEach((ax: any) => {
							ax.defaults.headers.common['Authorization'] = `Bearer ${token}`;
							ax.defaults.headers.common['x-gle-role'] = user.role.role_id;
							ax.defaults.headers.common['x-gle-current-season'] = '2223';
							ax.defaults.headers.common['x-gle-selected-season'] = '2223';
						});
					};

					setGoleeHeaderToAxios(interceptors);

					Conan.enrichUserProperties({ role: user.role.role }, user.sub);

					// set Auth store
					yield put({
						type: AUTH_ACTIONS.LOGIN_SUCCESS,
						georgian_token,
						token,
						client_id: '',
						login_completed: true,
						profile: { ...user },
					});
				} else {
					const err = 'Fallita chiamata resp_userperm';
					console.log('Login Error', err);
					yield put({ type: AUTH_ACTIONS.LOGIN_ERROR, error: 'SAGA_ERROR' });
				}
			} else {
				const err = '(georgian_token && token) return false';
				console.log('Login Error', err, georgian_token, token);
				yield put({ type: AUTH_ACTIONS.LOGIN_ERROR, error: 'SAGA_ERROR' });
			}
		} catch (err) {
			console.log('Login Error', err);
			yield put({ type: AUTH_ACTIONS.LOGIN_ERROR, error: 'SAGA_ERROR' });
		}
	});
}

export function* loginError() {
	yield takeEvery(AUTH_ACTIONS.LOGIN_ERROR, function* () {});
}

export default function* rootSaga() {
	yield all([fork(afterAuth0Login), fork(loginError)]);
}
