import { Button } from '@gle/base-ui.buttons.button';
import { CircleButton } from '@gle/base-ui.buttons.circle-button';
import { Tooltip } from '@gle/base-ui.tooltip';
import { Heading } from '@gle/base-ui.typography.heading';
import { push } from 'connected-react-router';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Table } from '../../../components/Table';
import { Column } from '../../../components/Table/Table';
import { ListViewProps } from '../../../providers';
import { useGroup } from '../../../providers/GroupProvider';
import { EPath } from '../../../router/types/path.enum';
import { Statement } from '../types';
import { CompetitionsFilters } from '../../../components/CompetitionsFilters';

type Props = {
	onNew?: () => void;
	onDelete?: (_id: string) => void;
	data: Statement[];
};

const View = ({ data, ...props }: ListViewProps & Props) => {
	const { championshipGroups } = useGroup();

	const columns: Column<Statement>[] = [
		{
			key: 'title',
			name: 'Numero',
			resizable: true,
			width: 200,
		},
		{
			key: 'step',
			name: 'Giornata',
			resizable: true,
			formatter: ({ row }) => (
				<>
					{row.steps
						.sort((a, b) => a - b)
						.map(s => `${s}`)
						.join(', ')}
				</>
			),
		},
		{
			key: 'ref_championship',
			name: 'Competizione',
			resizable: true,
			minWidth: 150,
			formatter: ({ row }) => (
				<>{championshipGroups.find(t => t.id === row.ref_championship)?.label || '-'}</>
			),
		},
		{
			key: 'date',
			name: 'Data',
			formatter: ({ row }) => <>{moment(row.date).format('DD/MM/YYYY')}</>,
		},
		{
			key: 'actions',
			name: 'Azioni',
			resizable: false,
			width: 120,
			headerCellClass: 'flex items-center justify-end',
			cellClass: 'flex items-center justify-end',
			formatter: ({ row }) => (
				<>
					<div style={{ marginRight: 20 }}>
						<Tooltip content="Scarica comunicato">
							<CircleButton
								onClick={() => window.open(row.url, '_blank')}
								level="primary">
								<i className="las la-download"></i>
							</CircleButton>
						</Tooltip>
					</div>

					<Tooltip content="Elimina comunicato">
						<CircleButton
							onClick={() => props.onDelete?.(row._id)}
							level="primary"
							color="danger">
							<i className="las la-trash"></i>
						</CircleButton>
					</Tooltip>
				</>
			),
		},
	];

	const dispatch = useDispatch();

	return (
		<>
			<div className="flex items-center justify-between mb-20 sm-flex-column sm-items-start sm-gap-20">
				<div className="flex gap-20">
					<Heading level={2}>Comunicati</Heading>
					<Button onClick={() => dispatch(push(EPath['statements_generate']))}>
						<i className="las la-plus"></i> Crea nuovo
					</Button>
				</div>

				<div className="flex items-center justify-end gap-20 sm-flex-column sm-items-start">
					<CompetitionsFilters
						onFilter={filters => props.onFilter?.(filters)}
						showOnlyChampionship
					/>
				</div>
			</div>

			<Table
				columns={columns}
				data={data.sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1))}
				isLoading={props.loading}
			/>
		</>
	);
};

export default View;
