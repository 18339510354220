import { gql, useLazyQuery } from '@apollo/client';
import { Loader } from '@gle/base-ui.loader';
import { Text } from '@gle/base-ui.typography.text';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Document, DocumentsService, MatchesService } from '../../services';
import { TDocType, TReportType } from '../../_types';
import { ManageUploaded, UploadButton } from '../Inputs/DocumentUploader/index';
import { Semaphore } from '../Semaphore';
import Toast from '../Toast';

type Props = {
	ref_match: string;
	documents?: Document[];
	doc_type: TDocType;
	ref_report: string | null;
	report_type: TReportType;
};

const query = (report_type: TReportType) => gql`
	query GetMatch($id: String!) {
		getMatch(id: $id) {
			id
			reports {
				${report_type}
			}
		}
	}
`;

const Component = ({ ref_match, doc_type, ref_report, report_type, ...props }: Props) => {
	const [documents, setDocuments] = useState<Document[]>(
		props.documents?.length ? props.documents : []
	);

	const [gqlGetMatch, { loading, error, data }] = useLazyQuery<any>(query(report_type), {
		variables: { id: ref_match },
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (data) {
			getDocuments();
		}
	}, [data]);

	useEffect(() => {
		gqlGetMatch();
	}, []);

	const getDocuments = async () => {
		const _documents: Document[] = [];
		if (data?.getMatch?.reports[report_type]) {
			try {
				for (const doc of data?.getMatch?.reports[report_type]) {
					const _ = await DocumentsService.get(doc, doc_type);
					_documents.push(_);
				}
			} catch (err) {}
		}
		setDocuments(_documents);
	};

	async function syncDocuments(_documents: Document[]) {
		await updateMatchReport(_documents);
		setDocuments(_documents);
	}

	const onModuleUploaded = async (file: File, url: string) => {
		try {
			const document = await createDocument(file.name, url, ref_match, doc_type);
			await syncDocuments(documents.concat([document]));
			Toast.success('Modulo allegato con successo');
		} catch (err) {
			console.error(err);
			Toast.error('Impossibile caricare il modulo');
		}
	};

	const onModuleRemoved = async (index_to_remove: number) => {
		try {
			await DocumentsService.delete(documents![index_to_remove]._id, doc_type);
			await syncDocuments(documents.filter((_, i) => i !== index_to_remove));
			Toast.success('Modulo rimosso');
		} catch (err) {
			console.error(err);
			Toast.error('Impossibile eliminare il modulo');
		}
	};

	const onModuleReplaced = async (index_to_replace: number, file: File, url: string) => {
		try {
			const new_document = await createDocument(file.name, url, ref_match, doc_type);
			await DocumentsService.delete(documents![index_to_replace]._id, doc_type);
			await syncDocuments(
				documents.map((document, i) => (i !== index_to_replace ? document : new_document))
			);
			Toast.success('Modulo sostituito con successo');
		} catch (err) {
			console.error(err);
			Toast.error('Impossibile sostituire il modulo');
		}
	};

	async function createDocument(
		name: string,
		url: string,
		refEntity: string,
		typeEntity: string
	) {
		return await DocumentsService.create({
			name,
			url,
			refEntity,
			typeEntity,
		});
	}

	async function updateMatchReport(_documents: Document[]) {
		await MatchesService.updateMatchReport(
			ref_match,
			report_type,
			_documents.map(d => d._id)
		);
	}

	function renderDetail() {
		return (
			<>
				<div className="flex gap-10">
					<Text size="s" color="gray">
						Modulo:
					</Text>

					{documents && documents[0] ? (
						<div className="flex gap-10">
							<Semaphore type="success" />
							<Text size="s">
								Caricato alle {moment(documents[0].createdAt).format('HH:mm')} del{' '}
								{moment(documents[0].createdAt).format('DD/MM/YYYY')}
							</Text>
						</div>
					) : (
						<div className="flex gap-10">
							<Semaphore type="danger" />
							<Text size="s">Non caricato</Text>
						</div>
					)}
				</div>
				<div className="flex flex-column justify-start mt-20" style={{ gap: 10 }}>
					{documents.map((document, index) => (
						<ManageUploaded
							index={String(index + 1)}
							name={document.name}
							url={document.url}
							onReplace={(file, url) => onModuleReplaced(index, file, url)}
							onRemove={() => onModuleRemoved(index)}
						/>
					))}
					<div>
						<UploadButton
							onUploadCompleted={onModuleUploaded}
							level={documents.length ? 'secondary' : 'primary'}
						/>
					</div>
				</div>
			</>
		);
	}

	return loading ? <Loader /> : renderDetail();
};

Component.defaultProps = { inline: false };

export default Component;
