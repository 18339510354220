import { Heading } from '@gle/base-ui.typography.heading';
import { Tooltip } from '@gle/base-ui.tooltip';
import { Button } from '@gle/base-ui.buttons.button';
import { StyledIcon } from '../index';
import { CompetitionsFilters } from '../../../components/CompetitionsFilters';

const SyncMatchesView = (props: {
	isSyncing: boolean;
	onFilter: (filters: any) => void;
	onSyncMatches: () => void;
}) => {
	return (
		<div className={'flex flex-column justify-start gap-20'}>
			<Heading level={4}>{'Sincronizza partite'}</Heading>
			<div className={'flex gap-20'}>
				<CompetitionsFilters onFilter={props.onFilter} />
				<Tooltip content={'Sincronizza'}>
					<Button
						disabled={props.isSyncing}
						level={'secondary'}
						onClick={props.onSyncMatches}>
						<StyledIcon>
							<i className={`las la-sync ${props.isSyncing ? 'animation' : ''}`} />
						</StyledIcon>
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

export { SyncMatchesView };
