import { Heading } from '@gle/base-ui.typography.heading';
import { useState } from 'react';
import { Search } from '../../../../../../components/Inputs';

import { Fine, Match } from '../../../../../../_types';
import { ClubSection } from './ClubSection';

const Index = ({
	match,
	fines,
	onChangeFines,
}: {
	match: Match;
	fines: Fine[];
	onChangeFines?: () => void;
}) => {
	const [searchName, setSearchName] = useState<string | undefined>();
	const [searchSurname, setSearchSurname] = useState<string | undefined>();

	if (!match) return <></>;
	return (
		<div>
			<Heading level={3}>Ammonizioni e sanzioni</Heading>
			<div
				style={{
					display: 'flex',
					margin: '20px 0',
					gap: 10,
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 10,
					}}>
					<Search onChange={setSearchSurname} placeholder="Cerca per cognome..." />
					<Search onChange={setSearchName} placeholder="Cerca per nome..." />
				</div>
			</div>
			<ClubSection
				club={match.home.club}
				ref_match={match.id}
				fines={fines}
				searchName={searchName}
				searchSurname={searchSurname}
				onChangeFines={onChangeFines}
			/>
			<br />
			<ClubSection
				club={match.away.club}
				ref_match={match.id}
				fines={fines}
				searchName={searchName}
				searchSurname={searchSurname}
				onChangeFines={onChangeFines}
			/>
		</div>
	);
};

export { Index };
