import { useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from './components/Sidebar/sidebar';
import Toolbar, { TOOLBAR_HEIGHT } from './components/Toolbar/toolbar';

type TProps = {
	children: React.ReactChild;
};

const Wrapper = styled.div`
	display: grid;
	height: 100vh;
	overflow: hidden;
	grid-template-rows: ${TOOLBAR_HEIGHT}px auto;
	grid-template-columns: 1fr;
`;

const Content = styled.div`
	overflow: auto;
	padding: 35px;

	@media screen and (max-width: 768px) {
		padding: 15px;
	}
`;

const Layout = ({ ...props }: TProps) => {
	useEffect(() => {}, []);

	return (
		<Wrapper>
			<Toolbar />
			<Content>{props.children}</Content>
		</Wrapper>
	);
};

export default Layout;
