import { Tooltip } from '@gle/base-ui.tooltip';
import { Text } from '@gle/base-ui.typography.text';
import { ERole } from '@golee/gle-types';
import { Button } from '@gle/base-ui.buttons.button';
import { Column, filterColumnsByRole } from '../../../components/Table/Table';
import { MatchListProps } from './types';
import { TMatchModulesConfig } from './view';
import { Semaphore } from '../../../components/Semaphore';

export const columns = (
	props: MatchListProps,
	onClickModules: (matchModuleConfig: TMatchModulesConfig) => void,
	role?: ERole
): Column<any>[] => {
	const cols: Column<any>[] = [
		{
			allowedRoles: [ERole.referee, ERole.league_delegate, ERole.federation_delegate],
			key: 'module',
			name: 'Modulo',
			resizable: true,
			minWidth: 150,
			cellClass: 'flex items-center',
			formatter: ({ row }) => {
				switch (role) {
					case ERole.referee:
						return (
							<Tooltip
								content={
									row[`reports.referee.0`]
										? 'Modulo caricato'
										: 'Modulo non caricato'
								}>
								<Button
									onClick={e => {
										e.stopPropagation();
										onClickModules({
											ref_match: row._id,
											doc_type: 'referee_module',
											ref_report: row['reports.referee'],
											report_type: 'referee',
										});
									}}>
									Allegati&nbsp;
									<Semaphore
										type={row[`reports.referee.0`] ? 'success' : 'danger'}
									/>
								</Button>
							</Tooltip>
						);
					case ERole.federation_delegate:
						return (
							<Tooltip
								content={
									row[`reports.federation.0`]
										? 'Modulo caricato'
										: 'Modulo non caricato'
								}>
								<Button
									onClick={e => {
										e.stopPropagation();
										onClickModules({
											ref_match: row._id,
											doc_type: 'federation_module',
											ref_report: row['reports.federation'],
											report_type: 'federation',
										});
									}}>
									Allegati&nbsp;
									<Semaphore
										type={row[`reports.federation.0`] ? 'success' : 'danger'}
									/>
								</Button>
							</Tooltip>
						);
					case ERole.league_delegate:
						return (
							<Tooltip
								content={
									row[`reports.league.0`]
										? 'Modulo caricato'
										: 'Modulo non caricato'
								}>
								<Button
									onClick={e => {
										e.stopPropagation();
										onClickModules({
											ref_match: row._id,
											doc_type: 'league_module',
											ref_report: row['reports.league'],
											report_type: 'league',
										});
									}}>
									Allegati&nbsp;
									<Semaphore
										type={row[`reports.league.0`] ? 'success' : 'danger'}
									/>
								</Button>
							</Tooltip>
						);
					default:
						throw new Error('Unexpected report role');
				}
			},
		},
		{
			key: 'home.club.name',
			name: 'Casa',
			minWidth: 250,
			cellClass: 'flex items-center gap-10',
			resizable: true,
			formatter: ({ row }) => {
				return (
					<>
						{row['home.club.logo'] && (
							<img
								src={row['home.club.logo']}
								alt={row['home.club.name']}
								style={{ width: 18 }}
							/>
						)}

						<Text>{row['home.club.name']}</Text>
					</>
				);
			},
		},
		{
			key: 'away.club.name',
			name: 'Ospite',
			minWidth: 250,
			cellClass: 'flex items-center gap-10',
			resizable: true,
			formatter: ({ row }) => {
				return (
					<>
						{row['away.club.logo'] && (
							<img
								src={row['away.club.logo']}
								alt={row['away.club.name']}
								style={{ width: 18 }}
							/>
						)}

						<Text>{row['away.club.name']}</Text>
					</>
				);
			},
		},
		{
			key: 'result',
			name: '',
			width: 10,
			formatter: ({ row }) => {
				return (
					<>
						{row['result.home']}-{row['result.away']}
					</>
				);
			},
		},
		{
			key: 'date',
			name: 'Data e ora',
			resizable: true,
			minWidth: 150,
			formatter: ({ row }) => {
				return (
					<>
						{row.date} alle {row.time}
					</>
				);
			},
		},

		{
			allowedRoles: [ERole.judge],
			key: 'judges.referee',
			name: 'Arbitro',
			minWidth: 200,
			cellClass: 'flex items-center',
			resizable: true,
			formatter: ({ row }: any) => {
				if (!row['judges.referee.name'])
					return <Text color="gray-light">Non assegnato</Text>;

				return (
					<Tooltip content={row['judges.referee.email']}>
						{row['judges.referee.name']} {row['judges.referee.surname']}
					</Tooltip>
				);
			},
		},

		{
			allowedRoles: [ERole.judge],
			key: 'judges.league_delegate',
			name: 'Delegato Lega Pro',
			cellClass: 'flex items-center',
			minWidth: 200,
			resizable: true,
			formatter: ({ row }: any) => {
				if (!row['judges.league_delegate.name'])
					return <Text color="gray-light">Non assegnato</Text>;

				return (
					<Tooltip content={row['judges.league_delegate.email']}>
						{row['judges.league_delegate.name']} {row['judges.league_delegate.surname']}
					</Tooltip>
				);
			},
		},

		{
			allowedRoles: [ERole.judge],
			key: 'judges.federation_delegate',
			name: 'Delegato FIGC',
			cellClass: 'flex items-center',
			minWidth: 200,
			resizable: true,
			formatter: ({ row }: any) => {
				if (!row['judges.federation_delegate.name'])
					return <Text color="gray-light">Non assegnato</Text>;

				return (
					<Tooltip content={row['judges.federation_delegate.email']}>
						{row['judges.federation_delegate.name']}{' '}
						{row['judges.federation_delegate.surname']}
					</Tooltip>
				);
			},
		},
	];

	return filterColumnsByRole(cols, role);
};
