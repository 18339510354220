import { FineForm } from './FinesForm/FineForm';
import { Modal } from '../../../../../../components/Modal/Modal';
import { Fine, Person } from '../../../../../../_types';
import { FineType } from '../../../../../../_types/enums';

type TProps = {
	onClose: () => void;
	onSubmit: () => void;
	refMatch: string;
	club: {
		id: number;
		name: string;
	};
};

type TPropsClub = {} & TProps;
export const FineFormModalClub = (props: TPropsClub) => {
	return (
		<Modal onClose={props.onClose} title={'Crea sanzione'}>
			<FineForm
				ref_match={props.refMatch}
				entity={'CLUB'}
				type={FineType.fine}
				ref_club={props.club.id}
				club_name={props.club.name}
				recipient={props.club.name}
				onCancel={props.onClose}
				onSubmit={props.onSubmit}
			/>
		</Modal>
	);
};

type TPropsClubEdit = { fine: Fine } & TPropsClub;
export const FineFormModalClubEdit = (props: TPropsClubEdit) => {
	return (
		<Modal onClose={props.onClose} title={'Aggiorna sanzione'}>
			<FineForm
				ref_match={props.refMatch}
				entity={'CLUB'}
				type={props.fine.type}
				ref_club={props.club.id}
				club_name={props.club.name}
				recipient={props.club.name}
				fine={props.fine}
				onCancel={props.onClose}
				onSubmit={props.onSubmit}
			/>
		</Modal>
	);
};

type TPropsPerson = {
	person: Person;
} & TProps;
export const FineFormModalPerson = (props: TPropsPerson) => {
	return (
		<Modal onClose={props.onClose} title={'Crea sanzione'}>
			<FineForm
				ref_match={props.refMatch}
				entity={props.person.role}
				ref_club={props.club.id}
				club_name={props.club.name}
				recipient={`${props.person.surname} ${props.person.name}`}
				ref_entity={props.person.goleeId}
				onCancel={props.onClose}
				onSubmit={props.onSubmit}
			/>
		</Modal>
	);
};

type TPropsPersonEdit = {
	fine: Fine;
} & TPropsPerson;
export const FineFormModalPersonEdit = (props: TPropsPersonEdit) => {
	return (
		<Modal onClose={props.onClose} title={'Aggiorna sanzione'}>
			<FineForm
				ref_match={props.refMatch}
				entity={props.person.role}
				ref_club={props.club.id}
				club_name={props.club.name}
				recipient={`${props.person.surname} ${props.person.name}`}
				ref_entity={props.person.goleeId}
				type={props.fine.type}
				fine={props.fine}
				onCancel={props.onClose}
				onSubmit={props.onSubmit}
			/>
		</Modal>
	);
};
