import axios from './interceptor';
import api from './api';

const service = {
	getUserPermissions: async (id: string) => {
		try {
			return await axios.get(api.getUserPermissions(id));
		} catch (err) {
			throw err;
		}
	},
};

export default service;