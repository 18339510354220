import { Tooltip } from '@gle/base-ui.tooltip';
import { useAuth0 } from '@auth0/auth0-react';

const Settings = () => {
	const { logout } = useAuth0();

	return (
		<Tooltip content={'Logout'}>
			<i
				className="las la-sign-out-alt"
				onClick={() => {
					logout({ returnTo: window.location.origin });
					localStorage.clear();
				}}></i>
		</Tooltip>
	);
};

export default Settings;
