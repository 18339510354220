import { Heading } from '@gle/base-ui.typography.heading';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MatchModules } from '../../../components/MatchModules';
import { Modal } from '../../../components/Modal/Modal';
import { Paginator, Table } from '../../../components/Table';
import Toast from '../../../components/Toast';
import { AppState } from '../../../redux/types';
import { TDocType, TReportType } from '../../../_types';
import { columns } from './columns';
import { CompetitionsFilters } from '../../../components/CompetitionsFilters';
import { MatchListProps } from './types';

export type TMatchModulesConfig = {
	ref_match: string;
	doc_type: TDocType;
	ref_report: string;
	report_type: TReportType;
};

const View = ({ ...props }: MatchListProps) => {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [matchModulesSetting, setMatchModulesSetting] = useState<TMatchModulesConfig>();

	const { role } = useSelector((store: AppState) => store.Auth.profile);

	useEffect(() => {
		if (props.error) {
			Toast.error(props.error.message);
		}
	}, [props.error]);

	const onClickModules = (matchModuleConfig: TMatchModulesConfig) => {
		setMatchModulesSetting(matchModuleConfig);
		setIsModalVisible(true);
	};

	return (
		<>
			<div className="flex items-center justify-between mb-20 sm-flex-column sm-items-start sm-gap-20">
				<Heading level={2}>Partite</Heading>
				<div className={'flex gap-20'}>
					<CompetitionsFilters
						onFilter={filters => props.onFilter?.(filters)}
						{...props}
					/>
				</div>
			</div>

			<Table
				columns={columns(props, onClickModules, role?.role)}
				data={props.data}
				onRowClick={props.onRowClick}
				isLoading={props.loading}
			/>
			{!props.loading && <Paginator {...props.pagination} />}

			{isModalVisible && matchModulesSetting && (
				<Modal onClose={() => setIsModalVisible(false)} title={'Moduli'}>
					<MatchModules
						ref_match={matchModulesSetting.ref_match}
						ref_report={matchModulesSetting.ref_report}
						report_type={matchModulesSetting.report_type}
						doc_type={matchModulesSetting.doc_type}
					/>
				</Modal>
			)}
		</>
	);
};

export default View;
