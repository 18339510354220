import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';
import Component, { CheckBoxProps } from './Component';

type Props = FieldConfig<any> & Pick<CheckBoxProps, 'render' | 'label'>;

const Wrapper = styled.div`
	width: 100%;
	height: 38px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}
`;

const Field = ({ ...props }: Props) => {
	const [field, meta, helpers] = useField<boolean>(props);

	return (
		<Wrapper className={`${meta.touched && meta.error ? 'error' : ''} `}>
			<Component {...props} {...field} onChange={helpers.setValue} />
			<ErrorMessage name={props.name} component="div" className="form-field-error" />
		</Wrapper>
	);
};

export default Field;
