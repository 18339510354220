import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ApiQueryParams } from '../../services';
import { RestDataProviderProps } from '../types';
import { RestDataContext } from './Context';

const Provider = (props: RestDataProviderProps) => {
	const [data, setData] = useState<any[]>([]);

	const [loading, setLoading] = useState<boolean>(true);

	const [limit, setLimit] = useState<number>(40);
	const [skip, setSkip] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const [filters, setFilters] = useState<any>({});
	const [search, setSearch] = useState<string | undefined>();
	const [sort, setSort] = useState<string | undefined>(undefined);

	// ------------------------------------------------
	// E v e n t s
	// ------------------------------------------------
	const onRefresh = () => {
		loadData();
	};

	const onSort = (value: string | undefined) => {
		if (_.isEqual(sort, value)) return;

		loadData({
			sort: value,
		});
		setSort(value);
	};

	const onFilter = (value: any) => {
		if (_.isEqual(filters, value)) return;

		loadData({ filters: value });
		setFilters(value);
	};

	const onSearch = (value: string | undefined) => {
		if (_.isEqual(search, value)) return;

		loadData({ search: value });
		setSearch(value);
	};

	const onPrevPage = () => {
		const newSkip = skip - limit;

		loadData({ skip: newSkip });
		setSkip(newSkip);
		setPage(newSkip / limit + 1);
	};

	const onNextPage = () => {
		const newSkip = skip + limit;

		loadData({ skip: newSkip });
		setSkip(newSkip);
		setPage(newSkip / limit + 1);
	};

	const onChoosePage = (newPage: number) => {
		const newSkip = (newPage - 1) * limit;

		loadData({ skip: newSkip });
		setSkip(newSkip);
		setPage(newSkip ? newSkip / limit + 1 : 1);
	};

	// ------------------------------------------------
	// D a t a
	// ------------------------------------------------

	/**
	 * Add default values from state if params are not set.
	 *
	 * @param params
	 * @returns
	 */
	const enrichParameters = (params: ApiQueryParams): ApiQueryParams => {
		if (!params.hasOwnProperty('skip')) params.skip = skip;
		if (!params.hasOwnProperty('limit')) params.limit = limit;

		if (!params.hasOwnProperty('sort')) params.sort = sort;
		if (!params.hasOwnProperty('filters')) params.filters = filters;

		if (!params.hasOwnProperty('search')) params.search = search;

		return params;
	};

	const loadData = async (params: ApiQueryParams = {}) => {
		params = enrichParameters(params);

		setLoading(true);

		try {
			const _: any[] = await props.api.function({ ...params, ...props.api.params });

			setHasMore(!(_.length < limit));

			setData(_);
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	// ------------------------------------------------
	// I n i t
	// ------------------------------------------------
	useEffect(() => {
		loadData();
	}, []);

	// ------------------------------------------------
	// R e n d e r
	// ------------------------------------------------
	return (
		<RestDataContext.Provider
			value={{
				data,
				loading,
				filters,
				onFilter,
				sort,
				onSort,
				search,
				onSearch,
				onRefresh,
				pagination: {
					page,
					canNextPage: hasMore,
					canPrevPage: skip > 0,
					onNextPage,
					onPrevPage,
					onChoosePage,
				},
			}}>
			{props.children}
		</RestDataContext.Provider>
	);
};

export default Provider;
