import { ApolloError, useQuery } from '@apollo/client';
import React, { ReactNode, useEffect } from 'react';
import { Fine, Match } from '../../../../../_types';
import { useGqlMatch } from './useGqlMatch';

export type TMatchDetailContext = {
	match?: Match;
	loading: boolean;
	reloadMatch: () => void;
};

export const MatchDetailContext = React.createContext<TMatchDetailContext>({
	match: undefined,
	loading: false,
	reloadMatch: () => {},
});

function MatchDetailProvider({
	id,
	children,
	onError,
}: {
	id: string;
	children: ReactNode;
	onError?: (error?: ApolloError) => void;
}) {
	const [loadMatchDetails, { loading, error, data }] = useGqlMatch(id);

	useEffect(() => {
		loadMatchDetails();
	}, []);

	useEffect(() => {
		if (error) {
			onError?.(error);
		}
	}, [error]);

	let match = undefined;
	if (data?.getMatch) {
		match = { ...data.getMatch };
		match.home.club.id = Number(match.home.club.id);
		match.away.club.id = Number(match.away.club.id);
	}

	return (
		<MatchDetailContext.Provider
			value={{
				match,
				loading,
				reloadMatch: () => {
					loadMatchDetails();
				},
			}}>
			{children}
		</MatchDetailContext.Provider>
	);
}

function useMatchDetailContext() {
	const context = React.useContext(MatchDetailContext);
	if (context === undefined) {
		throw new Error('useMatchDetailContext must be used within a MatchDetailProvider');
	}

	return context;
}

export { MatchDetailProvider, useMatchDetailContext };
