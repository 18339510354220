import { ERole } from '@golee/gle-types';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/types';

type Props = {
	allowedRoles: ERole[];
	children: React.ReactChild;
};

const Component = ({ ...props }: Props) => {
	const { role } = useSelector((store: AppState) => store.Auth.profile);

	if (!role || !role.role) {
		return <>{props.children}</>;
	}

	return <>{props.allowedRoles.includes(role.role) ? props.children : null}</>;
};

export default Component;
