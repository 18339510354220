import { gql, useLazyQuery } from '@apollo/client';

const query = gql`
	query GetMatch($id: String!) {
		getMatch(id: $id) {
			id
			date
			time
			groups {
				label
				type
			}
			step
			judges {
				referee {
					_id
					name
					surname
					email
				}
				federation_delegate {
					_id
					name
					surname
					email
				}
				league_delegate {
					_id
					name
					surname
					email
				}
			}
			fines {
				_id
				type
				amount
				suspension_step
				suspension_until
				label
				description
				recipient
				club_name
				entity_type
				club {
					id
					name
					logo
				}
			}
			home {
				id
				club {
					id
					organizationId
					name
					logo
				}
			}
			away {
				id
				club {
					id
					organizationId
					name
					logo
				}
			}
			result {
				home
				away
			}
		}
	}
`;

export const useGqlMatch = (matchId: string) => {
	return useLazyQuery<any>(query, {
		variables: { id: matchId },
		fetchPolicy: 'no-cache',
	});
};
