import React, { useContext, useEffect, useState } from 'react';
import { MatchesService } from '../services';
import { useGroup } from './GroupProvider';

export type Organization = {
	federationNumber: string;
	id: string;
	organizationId: string;
	name: string;
};

const OrganizationsContext = React.createContext<{
	organizations: Organization[];
	isLoading: boolean;
	getNameByOrganizationId: (organizationId: string) => string;
}>({
	organizations: [],
	isLoading: false,
	getNameByOrganizationId: organizationId => '',
});

export const OrganizationsProvider = ({ children }: any) => {
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { championshipGroups } = useGroup();

	const loadData = async () => {
		setIsLoading(true);

		let participants: any[] = [];

		for (const group of championshipGroups) {
			try {
				const groupParticipants = await MatchesService.getGroupCompetitors(group.id);
				participants = [
					...participants,
					...groupParticipants.clubCompetitors.filter(
						(c: any) => !participants.find(p => p.name === c.name)
					),
				];
			} catch (e) {
				console.log(JSON.stringify(e));
			}
		}

		setOrganizations(participants);
		setIsLoading(false);
	};

	useEffect(() => {
		if (!organizations.length && !isLoading) loadData();
	});

	const getNameByOrganizationId = (organizationId: string) => {
		return organizations.find(p => p.organizationId === organizationId)?.name || '';
	};

	return (
		<OrganizationsContext.Provider
			value={{ organizations, isLoading, getNameByOrganizationId }}>
			{children}
		</OrganizationsContext.Provider>
	);
};

export const useOrganizations = () => {
	const context = useContext(OrganizationsContext);
	if (!context) {
		throw new Error('useGroup must be used within OrganizationsProvider');
	}

	return context;
};
