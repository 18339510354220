import { useState } from 'react';
import { Organization, useOrganizations } from '../providers/OrganizationsProvider';

export const useOrganizationsSearch = (): {
	searchedOrganizations: Organization[];
	onSearch: (filters: { search?: string }) => void;
} => {
	const { organizations } = useOrganizations();
	const [searchedOrganizations, setSearchedOrganizations] = useState<Organization[]>([]);

	const onSearch = async ({ search }: { search?: string }) => {
		const result = search
			? organizations.filter(t => t.name.toLowerCase().includes(search))
			: [];
		setSearchedOrganizations(result);
	};

	return {
		searchedOrganizations,
		onSearch,
	};
};
