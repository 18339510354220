import styled from 'styled-components';
import ClubCard from './ClubCard';

type TProps = {
	match: any;
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	.club-card {
		display: flex;
		align-items: center;
		gap: 10px;
	}
`;

const Component = ({ match, ...props }: TProps) => {
	return (
		<Wrapper>
			<ClubCard club={match.home.club} />
			<ClubCard club={match.away.club} />
		</Wrapper>
	);
};

export default Component;
