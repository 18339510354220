import { ERole } from '@golee/gle-types';
import { MatchesService } from '../../services';

export const assignMatchJudge = async (match: string, role: ERole, id: string | null) => {
	if (!match) return;

	let payload: any = {};

	switch (role) {
		case ERole.referee:
			{
				payload = {
					ref_referee: id,
				};
			}
			break;
		case ERole.league_delegate:
			{
				payload = {
					ref_league_delegate: id,
				};
			}
			break;
		case ERole.federation_delegate:
			{
				payload = {
					ref_federation_delegate: id,
				};
			}
			break;
	}

	return assign(match, payload);
};

export const assignMatchJudges = async (
	match: string,
	referee?: string,
	league_delegate?: string,
	federation_delegate?: string
) => {
	if (!match) return;

	return assign(match, {
		ref_referee: referee,
		ref_league_delegate: league_delegate,
		ref_federation_delegate: federation_delegate,
	});
};

const assign = async (
	match: string,
	payload: {
		ref_referee?: string;
		ref_league_delegate?: string;
		ref_federation_delegate?: string;
	}
) => {
	try {
		await MatchesService.assignMatchJudges(match, payload);
	} catch (err) {
		throw err;
	}
};
