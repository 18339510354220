import { Group } from '../../redux/Championship/reducer';
import { Select } from '../Inputs';

const ChampionshipSelector = ({
	onChange,
	value,
	isClearable,
	championshipGroups,
}: {
	onChange: (id: string) => void;
	value: string;
	isClearable?: boolean;
	championshipGroups: Group[];
}) => {
	return (
		<Select
			placeholder="Competizione"
			onChange={e => onChange(e?.value)}
			value={value}
			options={championshipGroups.map(t => ({ value: t.id, label: t.label }))}
			isClearable={isClearable}
		/>
	);
};

export { ChampionshipSelector };
