import AccountForm from './AccountForm';
import ResultForm from './ResultForm';
import { CreatableFormProps } from './types';
import { EProfileRole } from '@golee/people-sdk';

const FederationDelegateForm = ({ ...props }: CreatableFormProps<any>) => {
	return <AccountForm role={EProfileRole.federation_delegate} {...props} />;
};

const RefereeForm = ({ ...props }: CreatableFormProps<any>) => {
	return <AccountForm role={EProfileRole.referee} {...props} />;
};

const LeagueDelegateForm = ({ ...props }: CreatableFormProps<any>) => {
	return <AccountForm role={EProfileRole.league_delegate} {...props} />;
};

export { AccountForm, RefereeForm, LeagueDelegateForm, FederationDelegateForm, ResultForm };
