import { Button } from '@gle/base-ui.buttons.button';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { SelectFormField } from '../../../../../../../components/Inputs';
import { InputDateField } from '../../../../../../../components/Inputs/DatePicker';
import { InputField } from '../../../../../../../components/Inputs/Input';
import Toast from '../../../../../../../components/Toast';
import { MatchesService } from '../../../../../../../services';
import { FineType } from '../../../../../../../_types/enums';
import { getFineLabels, getFineTypes } from './types';
import { validationSchema } from './validationSchema';
import { EEntity } from '../../../../../../../services/People/types';
import { Fine } from '../../../../../../../_types';

type FineFormValues = {
	recipient: string;
	entity: EEntity;
	club_name: string;
	type?: FineType;
	amount?: number;
	suspension_step?: number;
	suspension_until?: string;
	description?: string;
	label?: string;
	ref_entity?: string;
	ref_club?: number;
};

export const FineForm = ({
	ref_match,
	recipient,
	club_name,
	ref_club,
	entity,
	ref_entity,
	type,
	fine,
	...props
}: {
	ref_match: string;
	recipient: string;
	club_name: string;
	ref_club: number;
	entity: EEntity;
	type?: FineType;
	fine?: Fine;
	ref_entity?: string;
	onCancel: () => void;
	onSubmit: () => void;
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const create = async (values: FineFormValues) => {
		setIsLoading(true);

		try {
			await MatchesService.createFine(ref_match, {
				...values,
				club_name,
				ref_club,
				ref_match,
			});

			Toast.success('Sanzione creata correttamente');
			props.onSubmit();
		} catch (err) {
			console.log(err);
			Toast.error('Impossibile creare la sanzione');
		} finally {
			setIsLoading(false);
		}
	};

	const update = async (id: string, values: FineFormValues) => {
		setIsLoading(true);

		const ref_club = values.ref_club ? parseInt(`${values.ref_club}`) : undefined;

		try {
			await MatchesService.updateFine(ref_match, id, {
				...values,
				ref_club,
				ref_match: ref_match,
			});

			Toast.success('Sanzione aggiornata correttamente');
			props.onSubmit();
		} catch (err) {
			console.log(err);
			Toast.error('Impossibile aggiornare la sanzione');
		} finally {
			setIsLoading(false);
		}
	};

	const onDelete = async () => {
		if (!fine?._id) return;

		try {
			await MatchesService.removeFine(ref_match, fine?._id);
			Toast.success('Sanzione eliminata');
			props.onSubmit();
		} catch (err) {
			console.log(err);
			Toast.error("Impossibile eliminare l'sanzione");
		}
	};

	const onSubmit = async (values: FineFormValues) => {
		let data = { ...values };

		if (data.description) {
			data.description = data.description.replace(/\n/gm, ' ');
		}

		if (
			data.suspension_step &&
			(data.type === FineType.red_card ||
				values.type === FineType.suspension_no_red_card ||
				values.type === FineType.inhibition_with_expulsion ||
				values.type === FineType.inhibition_without_expulsion)
		) {
			data.suspension_step = parseInt(`${data.suspension_step}`);
		} else {
			data.suspension_step = undefined;
		}

		if (data.suspension_until) {
			data.suspension_until = moment(data.suspension_until, 'DD/MM/YYYY')
				.endOf('D')
				.toISOString();
		}

		if (data.amount) {
			data.amount = parseFloat(`${data.amount}`);
		} else {
			data.amount = undefined;
		}

		if (!fine?._id) {
			return create(data);
		} else {
			return update(fine?._id, data);
		}
	};

	return (
		<>
			<>
				<Formik
					enableReinitialize
					initialValues={{
						recipient,
						entity,
						club_name,
						ref_entity,
						type,
						amount: fine?.amount,
						suspension_step: fine?.suspension_step,
						description: fine?.description,
						suspension_until: fine?.suspension_until,
						label: fine?.label,
						ref_club,
					}}
					validationSchema={validationSchema}
					validateOnMount={false}
					validateOnBlur={false}
					onSubmit={onSubmit}>
					{formContext => {
						return (
							<Form>
								<div className="flex gap-20 flex-column">
									<>
										<InputField
											name="recipient"
											disabled
											label="Intestatario"
										/>

										{formContext.values.entity !== 'CLUB' && (
											<SelectFormField
												name="type"
												disabled={!!fine?._id}
												label="Tipologia"
												options={getFineTypes(
													formContext.values.entity
												).filter(t => {
													if (fine?._id) {
														return t;
													}

													return t.value !== FineType.yellow_card;
												})}
												onChange={(option: any) => {
													if (
														option?.value === FineType.red_card ||
														option?.value ===
															FineType.suspension_no_red_card
													) {
														if (!formContext.values.suspension_step) {
															formContext.setFieldValue(
																'suspension_step',
																1
															);
														}
													}
												}}
											/>
										)}

										{formContext.values.entity === 'CLUB' && (
											<SelectFormField
												name="label"
												disabled={!!fine?._id}
												label="Tipologia"
												options={getFineLabels()}
											/>
										)}

										<div className="flex gap-20 sm-flex-column">
											{formContext.values.type &&
												[
													FineType.fine,
													FineType.red_card,
													FineType.suspension_no_red_card,
													FineType.inhibition_with_expulsion,
													FineType.inhibition_without_expulsion,
												].includes(formContext.values.type) && (
													<InputField
														name="amount"
														label="Importo €"
														type="number"
														min={0}
													/>
												)}

											{formContext.values.type &&
												[
													FineType.red_card,
													FineType.suspension_no_red_card,
													FineType.inhibition_with_expulsion,
													FineType.inhibition_without_expulsion,
												].includes(formContext.values.type) && (
													<InputField
														name="suspension_step"
														label="Giornate di squalifica"
														type="number"
														min={0}
													/>
												)}

											{formContext.values.type &&
												[
													FineType.inhibition_with_expulsion,
													FineType.inhibition_without_expulsion,
												].includes(formContext.values.type) && (
													<InputDateField
														name="suspension_until"
														label="Squalifica fino a"
													/>
												)}
										</div>

										{formContext.values.type &&
											formContext.values.type !== FineType.yellow_card && (
												<InputField
													name="description"
													label="Descrizione"
													type="textarea"
												/>
											)}
									</>
								</div>

								<div className="flex justify-between mt-20">
									<div>
										{!!fine?._id && (
											<Button
												level="tertiary"
												color="danger"
												onClick={() => onDelete()}>
												Elimina
											</Button>
										)}
									</div>
									<div className="flex gap-20">
										<Button
											level="tertiary"
											onClick={props.onCancel}
											disabled={isLoading}>
											Annulla
										</Button>
										<Button type="submit" isLoading={isLoading}>
											Salva
										</Button>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
			</>
		</>
	);
};
