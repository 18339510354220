import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const _applyMiddleware = applyMiddleware(...middlewares);

const createRootReducer = (history: any) =>
	combineReducers({
		router: connectRouter(history),
		...reducers,
	} as any);

const store: any = createStore(
	createRootReducer(history),
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(_applyMiddleware)
		: compose(_applyMiddleware)
);

sagaMiddleware.run(rootSaga, {});

export { store, history };
