import { EEntity } from '../services/People/types';

export type Person = {
	name: string;
	surname: string;
	goleeId: string;
	role: EEntity;
	organizationIds: string[];
	matricola?: string;
	team?: ETeam;
	birthdate: string;
};

export enum ETeam {
	'FIRST_TEAM' = 'FIRST_TEAM',
	'YOUTH_TEAM' = 'YOUTH_TEAM',
	'REGISTERED' = 'REGISTERED',
}
