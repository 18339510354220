import { InputPhone, InputPhoneProps } from '@gle/base-ui.inputs.input-phone';
import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

type Props = FieldConfig<any> & Omit<InputPhoneProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'>;

const Wrapper = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	&.h-auto {
		height: auto;
	}

	label {
		font-size: var(--s-size);
		display: block;
		color: var(--gray-light-color);
	}

	input,
	select {
		box-sizing: border-box;
	}

	input {
		width: 100%;
		padding: 0 7px;
		border: 1px solid var(--gray-lightest-color);
		border-radius: 4px;
		appearance: none;
		outline: none;
		background: transparent;
		height: 38px;
		font-size: var(--m-size);
		font-family: var(--font-family);
	}

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}
		input {
			border-color: var(--danger-color);
		}
	}
`;

const Field = ({ ...props }: Props) => {
	const [field, meta, helpers] = useField(props);

	return (
		<Wrapper className={`${meta.touched && meta.error ? 'error' : ''}`}>
			<InputPhone
				{...field}
				{...props}
				onChange={helpers.setValue}
				// TODO: fix this
				type="text"
			/>
			<ErrorMessage name={props.name} component="div" className="form-field-error" />
		</Wrapper>
	);
};

export default Field;
