import { gql } from '@apollo/client';
import { ERole } from '@golee/gle-types';
import { push } from 'connected-react-router';
import flat from 'flat';
import { useDispatch, useSelector } from 'react-redux';
import { GraphQLDataContext, GraphQLDataProvider } from '../../../providers';
import { AppState } from '../../../redux/types';
import { EPath } from '../../../router/types/path.enum';
import View from './view';
import { useEffect } from 'react';
import { useFilters } from '../../../providers/FiltersProvider';

const query = gql`
	query GetMatches($filters: MatchFilters!, $opts: MatchesListOpts) {
		getMatches(filters: $filters, opts: $opts) {
			id
			date
			time
			step
			judges {
				referee {
					_id
					name
					surname
					email
				}
				federation_delegate {
					_id
					name
					surname
					email
				}
				league_delegate {
					_id
					name
					surname
					email
				}
			}
			home {
				id
				club {
					name
					logo
				}
			}
			away {
				id
				club {
					name
					logo
				}
			}
			result {
				home
				away
			}
			reports {
				referee
				federation
				league
			}
		}
	}
`;

const Container = () => {
	const { selectedChampionshipGroup, selectedRoundGroup, selectedStepGroup } = useFilters();
	const { role } = useSelector((state: AppState) => state.Auth.profile);
	const { entity_ref } = useSelector((state: AppState) => state.Auth.user_permissions);

	const dispatch = useDispatch();

	const fixedFilters = (): { [key: string]: number | string } => {
		if (!role || !role.role) {
			return {};
		}

		if ([ERole.referee, ERole.league_delegate, ERole.federation_delegate].includes(role.role)) {
			return {
				profile_id: entity_ref,
			};
		}

		return {};
	};

	useEffect(() => {}, []);

	if (!(selectedChampionshipGroup.id && selectedRoundGroup.id && selectedStepGroup.id))
		return <>Caricamento...</>;

	return (
		<GraphQLDataProvider
			id="matches-list"
			limit={20}
			query={query}
			fixedFilters={fixedFilters()}
			defaultSort={'competition.step'}>
			<GraphQLDataContext.Consumer>
				{context => {
					return (
						<>
							<View
								error={context.error}
								data={
									context.data
										? context.data.getMatches.map((t: any) => flat(t))
										: []
								}
								onRowClick={row => {
									if (role?.role === ERole.judge) {
										dispatch(push(EPath.matches + '/' + row.id));
									}
								}}
								loading={context.loading}
								onFilter={context.onFilter}
								filters={context.filters}
								onSearch={context.onSearch}
								onSort={context.onSort}
								pagination={{
									...context.pagination,
									canChoosePage: true,
								}}
							/>
						</>
					);
				}}
			</GraphQLDataContext.Consumer>
		</GraphQLDataProvider>
	);
};

export default Container;
