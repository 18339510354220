import axios from './interceptor';
import api from './api';
import { ApiQueryParams } from '../types';
import { EProfileRole } from '@golee/people-sdk';
import { EUserRole } from '@golee/guardian-sdk';

const service = {
	getProfiles: async ({ filters, ...params }: ApiQueryParams) => {
		try {
			const res = await axios.get(api.getProfiles(), {
				params: { ...params, ...filters },
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	createProfile: async (data: {
		name: string;
		surname: string;
		phone: string;
		role: EProfileRole;
	}): Promise<{ _id: string }> => {
		try {
			const res = await axios.post(api.createProfile(), data);
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	createInvite: async (
		profile_id: string,
		data: { label: string; profileRole: EProfileRole; userRole: EUserRole }
	): Promise<{ id: string }> => {
		try {
			const res = await axios.post(api.createInvite(profile_id), data);
			return res.data;
		} catch (err) {
			throw err;
		}
	},
};

export default service;
