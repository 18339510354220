import { Heading } from '@gle/base-ui.typography.heading';
import { useEffect, useState } from 'react';
import { Search } from '../../components/Inputs';
import { usePeople } from '../../hooks/usePeople';
import { useOrganizationsSearch } from '../../hooks/useOrganizationsSearch';
import { CardSectionClub, CardSectionPeople } from './cardsSection';
import { Text } from '@gle/base-ui.typography.text';
import { Loader } from '@gle/base-ui.loader';

export const SearchPage = () => {
	const [searchName, setSearchName] = useState<string | undefined>(
		new URLSearchParams(window.location.search).get('searchName') || undefined
	);
	const [searchSurname, setSearchSurname] = useState<string | undefined>(
		new URLSearchParams(window.location.search).get('searchSurname') || undefined
	);
	const [searchOrganization, setSearchOrganization] = useState<string | undefined>();
	const { people, onSearch: onSearchPeople, isLoading: isLoadingPeople } = usePeople();
	const { searchedOrganizations, onSearch: onSearchOrganizations } = useOrganizationsSearch();

	useEffect(() => {
		onSearchPeople({
			searchName,
			searchSurname,
		});
		onSearchOrganizations({ search: searchOrganization });
	}, [searchName, searchSurname, searchOrganization]);

	const render = () => {
		if (!searchName && !searchSurname && !searchOrganization) {
			return (
				<Text style={{ marginTop: 20 }} color="gray">
					Effettua una ricerca...
				</Text>
			);
		}

		if (!people.length && !searchedOrganizations.length) {
			return (
				<Text style={{ marginTop: 20 }} color="gray">
					Nessun risultato trovato
				</Text>
			);
		}

		return (
			<div style={{ marginTop: 30 }}>
				{searchOrganization && <CardSectionClub clubs={searchedOrganizations} />}
				{(searchName || searchSurname) && <CardSectionPeople people={people} />}
			</div>
		);
	};

	return (
		<div>
			<Heading level={2} style={{ marginBottom: 20 }}>
				Cerca giocatori, tecnici, dirigenti, società
			</Heading>
			<div className={'flex flex-row items-center gap-20'} style={{ height: '100%' }}>
				<Search
					onChange={setSearchSurname}
					placeholder={'Cerca per cognome...'}
					value={searchSurname}
					width={200}
				/>
				<Search
					onChange={setSearchName}
					placeholder={'Cerca per nome...'}
					value={searchName}
					width={200}
				/>
				<Search
					onChange={setSearchOrganization}
					placeholder={'Cerca società...'}
					value={searchOrganization}
					width={200}
				/>
				{isLoadingPeople && <Loader />}
			</div>
			{render()}
		</div>
	);
};
