import { store as Store } from '../../redux/store';
import {
	trackEvent as conanTrackEvent,
	enrichUserProperties as conanEnrichUserProperties,
} from './service';
import { browserName, osName, browserVersion } from 'react-device-detect';
import { AppState } from '../../redux/types';

export class Conan {
	public static trackEvent = async (
		event_name: string,
		extra_properties?: any,
		distinct_id?: string
	) => {
		const store: AppState = Store.getState();
		const user_sub = store.Auth.profile?.sub;

		conanTrackEvent({
			event: event_name,
			distinct_id: distinct_id || user_sub,
			properties: {
				...(await Conan.getClientInfo()),
				...extra_properties,
				$user_id: user_sub,
			},
		})
			.then(_ => {
				console.log(`[conan] event ${event_name} tracked.`);
			})
			.catch(_ => {
				console.log(`[conan] failed to track ${event_name} event.`);
			});
	};

	public static enrichUserProperties = async (properties: any, distinct_id?: string) => {
		const store: AppState = Store.getState();
		const user_sub = store.Auth.profile?.sub;

		conanEnrichUserProperties({
			distinct_id: distinct_id || user_sub,
			properties,
		})
			.then(_ => {
				console.log(
					`[conan] user ${distinct_id || user_sub} enriched with ${Object.keys(
						properties
					).join(', ')} properties.`
				);
			})
			.catch(_ => {
				console.log(`[conan] failed to enrich ${distinct_id || user_sub} user.`);
			});
	};

	public static getClientInfo = async (): Promise<TClientInfo> => {
		const user_position = await getExactUserPosition();
		return {
			$os: osName,
			$browser: browserName,
			$current_url: window.location.href,
			$browser_version: browserVersion,
			$screen_height: window.screen.height as any as string,
			$screen_width: window.screen.width as any as string,
			position: user_position,
		};
	};
}

type TUserLocation = { lat: number; lon: number };

type TClientInfo = {
	$os: string;
	$browser: string;
	$current_url: string;
	$browser_version: string;
	$screen_height: string;
	$screen_width: string;
	position?: TUserLocation;
};

const getExactUserPosition = (): Promise<TUserLocation | undefined> =>
	new Promise((resolve: any, reject: any) => {
		try {
			navigator.geolocation.getCurrentPosition(
				position => {
					resolve({ lat: position.coords.latitude, lon: position.coords.longitude });
				},
				(err: any) => {
					resolve(undefined);
				}
			);
		} catch (error) {
			resolve(undefined);
		}
	});
