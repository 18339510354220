import { Button } from '@gle/base-ui.buttons.button';
import { isValidDate } from '@gle/base-ui.inputs.input-date';
import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { InputDateField } from '../../../components/Inputs/DatePicker';
import { InputField } from '../../../components/Inputs/Input';
import { Table } from '../../../components/Table';
import { columns } from './columns';
import { GenerateStatementProps } from './types';
import { CompetitionsFilters } from '../../../components/CompetitionsFilters';

export const View = (props: GenerateStatementProps) => {
	return (
		<div>
			<Heading level={2}>{'Crea nuovo comunicato'}</Heading>
			<div className={'flex mt-20'}>
				<div className={'flex flex-column gap-10'} style={{ flex: 3 / 5 }}>
					<Text size={'l'}>{'1. Seleziona partite'}</Text>
					<CompetitionsFilters
						onFilter={filters => {
							props.onFilter?.(filters);
						}}
					/>
					<Table
						columns={columns(
							props.selectedMatches,
							() => props.onSelectAll(props.data),
							props.isSelectAllActive
						)}
						data={props.data}
						onRowClick={props.onRowClick}
						isLoading={props.loading}
					/>
				</div>
				<div
					className={'flex'}
					style={{
						flex: 2 / 5,
						borderLeft: '1px solid lightgray',
					}}>
					<div
						className={'flex flex-column gap-20'}
						style={{ margin: '0 20px', flex: 1 }}>
						<GenerationForm
							selectedMatches={props.selectedMatches}
							onSubmit={props.onSubmit}
							isSubmitLoading={props.isSubmitLoading}
						/>
						<PreviewSelectedMatch
							selectedMatches={props.selectedMatches}
							onRemoveAllSelection={props.onRemoveAllSelection}
							onRowClick={match => props.onRowClick && props.onRowClick(match)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const GenerationForm = (props: {
	selectedMatches: any[];
	onSubmit: (value: any) => void;
	isSubmitLoading: boolean;
}) => (
	<div>
		<Text size={'l'}>{'2. Aggiungi numero e data'}</Text>
		<Formik
			enableReinitialize
			validationSchema={Yup.object().shape({
				startPagesFrom: Yup.number().required('Dato obbligatorio'),
				statement_title: Yup.string().required('Dato obbligatorio'),
				statement_date: Yup.string().test('date', 'Data non valida', value =>
					isValidDate(value, 'DD/MM/YYYY', false)
				),
			})}
			initialValues={{
				startPagesFrom: 1,
				statement_title: undefined,
				statement_date: moment().format('DD/MM/YYYY'),
			}}
			validateOnMount={false}
			validateOnBlur={false}
			onSubmit={(values: any) => {
				props.onSubmit({
					...values,
					startPagesFrom: parseInt(`${values.startPagesFrom}`),
				});
			}}>
			{formContext => {
				return (
					<Form autoComplete="off">
						<div className={'mt-30 flex flex-column gap-20'}>
							<InputField name="statement_title" label="Numero comunicato" />
							<InputDateField name="statement_date" label="Data" />
							<InputField
								name="startPagesFrom"
								min={1}
								placeholder="1"
								type="number"
								label="Num. Pagine a partire da"
							/>
						</div>

						<Button
							className={'mt-10'}
							type="submit"
							isLoading={props.isSubmitLoading}
							disabled={!(formContext.isValid && props.selectedMatches.length)}>
							Genera
						</Button>
					</Form>
				);
			}}
		</Formik>
	</div>
);

const PreviewSelectedMatch = (props: {
	selectedMatches: any[];
	onRemoveAllSelection: () => void;
	onRowClick: (match: any) => void;
}) => (
	<div>
		<div
			className={'flex justify-between items-center my-20'}
			style={{
				paddingTop: 10,
				borderTop: '1px solid lightgray',
			}}>
			<Text size={'l'} color={'gray'}>
				{`Hai selezionato ${props.selectedMatches.length} ${
					props.selectedMatches.length === 1 ? 'partita' : 'partite'
				}`}
			</Text>
			{props.selectedMatches.length ? (
				<Button level={'tertiary'} color={'danger'} onClick={props.onRemoveAllSelection}>
					Rimuove tutte
				</Button>
			) : undefined}
		</div>
		<div className={'flex flex-column gap-10'}>
			{props.selectedMatches.map(match => (
				<SelectedMatchRow match={match} onRowClick={props.onRowClick} />
			))}
		</div>
	</div>
);

const SelectedMatchRow = ({
	match,
	onRowClick,
}: {
	match: any;
	onRowClick?: (match: any) => void;
}) => {
	return (
		<div
			style={{
				width: '100%',
				paddingBottom: 5,
				borderBottom: '1px solid lightgray',
			}}>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '5fr 3fr auto',
					gap: 20,
				}}>
				<div className={'flex flex-column'}>
					<ClubRow logo={match['home.club.logo']} name={match['home.club.name']} />
					<ClubRow logo={match['away.club.logo']} name={match['away.club.name']} />
				</div>
				<div className={'flex flex-column justify-between'}>
					<Text color={'gray'} size={'m'}>
						{`Data ${match['date']}`}
					</Text>
					{/* <Text color={'gray'} size={'m'}>
						{`${match['competition.step']}, girone ${match['competition.round_name']}`}
					</Text> */}
				</div>
				<Button
					level={'tertiary'}
					color={'gray'}
					onClick={() => onRowClick && onRowClick(match)}>
					{'x'}
				</Button>
			</div>
		</div>
	);
};

const ClubRow = ({ logo, name }: { logo?: string; name?: string }) => (
	<div className={'flex gap-10 items-center'}>
		{logo && <img src={logo} alt={name} style={{ width: 12 }} />}
		<Text
			style={{
				width: '17vw',
				display: 'inline-block',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			}}
			color={'gray'}>
			{name}
		</Text>
	</div>
);
