import { ERole } from "@golee/gle-types";

export type AuthState = {
	access_token: string | null;
	georgian_token: string | null;
	login_error: any;
	login_completed: boolean;
	user_permissions: any;
	client_id: string | null;
	profile: {
		sub?: string;
		name?: string;
		nickname?: string;
		picture?: string;
		role?: {
			role_id?: string;
			role?: ERole;
		}
	};
};

export enum ACTIONS {
	AFTER_AUTH0_LOGIN = 'AFTER_AUTH0_LOGIN',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	LOGIN_ERROR = 'LOGIN_ERROR',
	SETPERMISSIONFEATURES = 'SETPERMISSIONFEATURES',
	SET_LOGINCOUNT = 'SET_LOGINCOUNT',
}
