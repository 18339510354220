import { SyncMatchesView } from './view';
import { useState } from 'react';
import { MatchesService } from '../../../services';
import Toast from '../../../components/Toast';
import { useFilters } from '../../../providers/FiltersProvider';

const SyncMatchesContainer = () => {
	const { selectedChampionshipGroup, selectedRoundGroup, selectedStepGroup } = useFilters();

	const [isSyncing, setIsSyncing] = useState<boolean>(false);

	const onSyncMatches = async () => {
		setIsSyncing(true);
		try {
			await MatchesService.syncMatchesByStep({
				season: '2223',
				competitionId: selectedChampionshipGroup.id,
				roundId: selectedRoundGroup.id,
				stepId: selectedStepGroup.id,
			});
			Toast.success('Partite sincronizzate');
			// context.loadData && context.loadData();
		} catch (e) {
			Toast.error('Errore nella sincronizzazione');
		} finally {
			setIsSyncing(false);
		}
	};

	return (
		<SyncMatchesView onSyncMatches={onSyncMatches} isSyncing={isSyncing} onFilter={() => {}} />
	);
};
export { SyncMatchesContainer };
