import { Heading } from '@gle/base-ui.typography.heading';
import { Tooltip } from '@gle/base-ui.tooltip';
import { Button } from '@gle/base-ui.buttons.button';
import { StyledIcon } from '../index';
import { Search } from '../../../components/Inputs';
import { Text } from '@gle/base-ui.typography.text';
import { string } from 'yup';

const SyncClubView = (props: {
	onSearch: (value: string | undefined) => void;
	organizations: { id: string; name: string }[];
	isSyncing: boolean;
	onSyncClub: (organizationId: string) => void;
}) => {
	return (
		<div className={'flex flex-column justify-start gap-20'}>
			<Heading level={4}>{'Sincronizza tesserati'}</Heading>
			<div>
				<Search placeholder={'Cerca club...'} onChange={props.onSearch} />
				{props.organizations.map(p => (
					<div className={'flex items-center gap-10 mt-10'}>
						<Tooltip content={'Sincronizza tesserati'}>
							<Button
								disabled={props.isSyncing}
								level={'secondary'}
								onClick={() => props.onSyncClub(p.id)}>
								<StyledIcon>
									<i
										className={`las la-sync ${
											props.isSyncing ? 'animation' : ''
										}`}
									/>
								</StyledIcon>
							</Button>
						</Tooltip>
						<Text>{p.name}</Text>
					</div>
				))}
			</div>
		</div>
	);
};

export { SyncClubView };
