import { ChampionshipSelector, RoundSelector, StepSelector } from '../CompetitionSelectors';
import { useGroup } from '../../providers/GroupProvider';
import { useEffect } from 'react';
import { useFilters } from '../../providers/FiltersProvider';

export const CompetitionsFilters = ({
	onFilter,
	showOnlyChampionship,
}: {
	onFilter: (filters: { competitionId: string; roundId: string; stepId: string }) => void;
	showOnlyChampionship?: boolean;
}) => {
	const { championshipGroups, getRoundGroups, getStepGroups } = useGroup();

	const {
		selectedChampionshipGroup,
		selectedRoundGroup,
		selectedStepGroup,
		onChampionshipChange,
		onRoundChange,
		onStepChange,
	} = useFilters();

	useEffect(() => {
		if (selectedChampionshipGroup.id && selectedRoundGroup.id && selectedStepGroup.id) {
			onFilter &&
				onFilter({
					competitionId: selectedChampionshipGroup.id,
					roundId: selectedRoundGroup.id,
					stepId: selectedStepGroup.id,
				});
		}
	}, [selectedChampionshipGroup, selectedRoundGroup, selectedStepGroup]);

	return (
		<div className="flex items-center gap-20 sm-flex-column sm-items-start">
			<ChampionshipSelector
				championshipGroups={championshipGroups}
				value={selectedChampionshipGroup.id}
				onChange={onChampionshipChange}
				isClearable={false}
			/>
			{!showOnlyChampionship && (
				<>
					<RoundSelector
						roundGroups={getRoundGroups(selectedChampionshipGroup.id)}
						value={selectedRoundGroup.id}
						onChange={roundId => roundId && onRoundChange(roundId)}
						isClearable={false}
					/>
					<StepSelector
						stepGroups={getStepGroups(
							selectedChampionshipGroup.id,
							selectedRoundGroup.id
						)}
						value={selectedStepGroup.id}
						onChange={stepId => stepId && onStepChange(stepId)}
						isClearable={false}
					/>
				</>
			)}
		</div>
	);
};
