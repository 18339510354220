import { useState } from 'react';
import styled from 'styled-components';
import { MatchesService } from '../../services';
import { Match } from '../../_types';
import Toast from '../Toast';

type Props = {
	match?: Match;
};

const Wrapper = styled.div`
	display: flex;
	align-self: stretch;
	flex-direction: column;
	justify-content: space-between;
	input {
		display: block;
		width: 35px;
		text-align: center;
		padding: 3px;
		border: 1px solid var(--gray-lightest-color);
		border-radius: 4px;
		appearance: none;
		outline: none;
		background: transparent;
		font-size: var(--l-size);
		font-weight: var(--font-weight-medium);
	}
`;

const Component = ({ match, ...props }: Props) => {
	const [homeResult, setHomeResult] = useState<number | null>(
		match?.result?.home || match?.result?.home === 0 ? match?.result?.home : null
	);
	const [awayResult, setAwayResult] = useState<number | null>(
		match?.result?.away || match?.result?.away === 0 ? match?.result?.away : null
	);

	const updateResult = async (payload: { home: number | null; away: number | null }) => {
		if (!((payload.home || payload.home === 0) && (payload.away || payload.away === 0))) return
		try {
			await MatchesService.updateMatchResult(match!.id, payload);
		} catch (err) {
			Toast.error("Errore durante l'aggiornamento del risultato");
		}
	};

	const onChangeHome = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value, 10);
		setHomeResult(value);

		updateResult({
			home: value,
			away: awayResult,
		});
	};

	const onChangeAway = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value, 10);
		setAwayResult(value);

		updateResult({
			home: homeResult,
			away: value,
		});
	};

	return (
		<Wrapper>
			<input
				type="number"
				min={0}
				value={homeResult || homeResult === 0 ? homeResult : undefined}
				onChange={onChangeHome}
			/>
			<input
				type="number"
				min={0}
				value={awayResult || awayResult === 0 ? awayResult : undefined}
				onChange={onChangeAway}
			/>
		</Wrapper>
	);
};

export default Component;
