import { Input, InputProps } from '@gle/base-ui.inputs.input';
import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

type Props = FieldConfig<any> & Omit<InputProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'>;

const Wrapper = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	flex-direction: column;
	column-gap: 3px;
	row-gap: 3px;
	gap: 3px;

	&.h-auto {
		height: auto;
	}

	&.password-strength-enabled {
		height: 90px;
	}

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);

		&.password-strength-enabled {
			position: relative;
			top: -5px;
		}
	}

	&.error {
		label {
			color: var(--danger-color);
		}
		input {
			border-color: var(--danger-color);
		}
	}
`;

const Field = ({ style = {}, ...props }: Props) => {
	const [field, meta, helpers] = useField(props);

	return (
		<Wrapper
			style={style}
			className={`${meta.touched && meta.error ? 'error' : ''} ${
				props.type === 'textarea' ? 'h-auto' : ''
			} ${props.checkPasswordStrength ? 'password-strength-enabled' : ''}`}>
			<Input {...props} {...field} onChange={helpers.setValue} />
			<ErrorMessage
				name={props.name}
				component="div"
				className={`form-field-error ${
					props.checkPasswordStrength && field.value ? 'password-strength-enabled' : ''
				}`}
			/>
		</Wrapper>
	);
};

export default Field;
