import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import { ERole } from '@golee/gle-types';
import {
	FederationDelegateForm,
	LeagueDelegateForm,
	RefereeForm,
} from '../../../../../components/Forms';
import { CreatableSelect } from '../../../../../components/Inputs';
import { MatchModules as ModulesComponent } from '../../../../../components/MatchModules';
import Toast from '../../../../../components/Toast';
import { loadProfilesOptions } from '../../../../../services';
import { Match } from '../../../../../_types';
import { assignMatchJudge } from '../../../assignJudges';

const MatchModules = ({
	match,
	onModuleChanged,
}: {
	match?: Match;
	onModuleChanged?: () => void;
}) => {
	const onAssignJudge = async (role: ERole, id: string | null, name?: string) => {
		try {
			match && (await assignMatchJudge(match.id, role, id));

			if (id) {
				Toast.success('Assegnazione effettuata');
			} else {
				Toast.warning('Assegnazione rimossa');
			}

			onModuleChanged?.();
		} catch (err) {
			Toast.error("Si è verificato un errore durante l'assegnazione");
		}
	};

	if (!match) return <></>;
	return (
		<div>
			<Heading level={4}>Moduli</Heading>
			<div className="pb-30 pt-20 gap-50 grid justify-between flex-column">
				<div className="flex flex-column">
					<Text className="mb-5" color="gray">
						Arbitro
					</Text>

					<CreatableSelect
						createForm={RefereeForm}
						createModalTitle="Crea nuovo arbitro"
						placeholder="Cerca..."
						value={
							match.judges?.referee
								? {
										value: match.judges?.referee?._id,
										label: match.judges?.referee?.name.concat(
											' ',
											match.judges?.referee?.surname || ''
										),
								  }
								: null
						}
						onChange={option => {
							onAssignJudge(
								ERole.referee,
								option?.value || null,
								option?.label || null
							);
						}}
						loadOptions={search => loadProfilesOptions(ERole.referee, search)}
					/>

					<div className="mt-20">
						<ModulesComponent
							report_type={'referee'}
							ref_report={match?.reports?.referee ? match.reports.referee[0] : null}
							doc_type={'referee_module'}
							ref_match={match.id}
						/>
					</div>
				</div>

				<div className="flex flex-column">
					<Text className="mb-5" color="gray">
						Delegato Lega Pro
					</Text>
					<CreatableSelect
						createForm={LeagueDelegateForm}
						createModalTitle="Crea nuovo delegato Lega Pro"
						placeholder="Cerca..."
						onChange={val => {
							onAssignJudge(
								ERole.league_delegate,
								val?.value || null,
								val?.label || null
							);
						}}
						value={
							match.judges?.league_delegate
								? {
										value: match.judges?.league_delegate?._id,
										label: match.judges?.league_delegate?.name.concat(
											' ',
											match.judges?.league_delegate?.surname || ''
										),
								  }
								: null
						}
						loadOptions={search => loadProfilesOptions(ERole.league_delegate, search)}
					/>
					<div className="mt-20">
						<ModulesComponent
							report_type={'league'}
							ref_report={match?.reports?.league ? match.reports.league[0] : null}
							doc_type={'league_module'}
							ref_match={match.id}
						/>
					</div>
				</div>

				<div className="flex flex-column">
					<Text className="mb-5" color="gray">
						Delegato FIGC
					</Text>
					<CreatableSelect
						createForm={FederationDelegateForm}
						createModalTitle="Crea nuovo delegato FIGC"
						placeholder="Cerca..."
						onChange={val => {
							onAssignJudge(
								ERole.federation_delegate,
								val?.value || null,
								val?.label || null
							);
						}}
						value={
							match.judges?.federation_delegate
								? {
										value: match.judges?.federation_delegate?._id,
										label: match.judges?.federation_delegate?.name.concat(
											' ',
											match.judges?.federation_delegate?.surname || ''
										),
								  }
								: null
						}
						loadOptions={search =>
							loadProfilesOptions(ERole.federation_delegate, search)
						}
					/>
					<div className="mt-20">
						<ModulesComponent
							report_type={'federation'}
							ref_report={
								match?.reports?.federation ? match.reports.federation[0] : null
							}
							doc_type={'federation_module'}
							ref_match={match.id}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export { MatchModules };
