import moment from 'moment';
import { Person, TReportType } from '../../_types';
import { ApiQueryParams } from '../types';
import api from './api';
import axios from './interceptor';
import { TPartialStatement } from './types';
import { FineType } from '../../_types/enums';
import { Athlete, EEntity, Staff } from '../People/types';

const service = {
	assignMatchJudges: async (
		id: string,
		data: {
			ref_referee?: string;
			ref_league_delegate?: string;
			ref_federation_delegate?: string;
		}
	): Promise<void> => {
		try {
			await axios.post(api.assignMatchJudges(id), data);
		} catch (err) {
			throw err;
		}
	},

	updateMatchReport: async (
		id: string,
		report_type: TReportType,
		report_documents: string[] | null
	): Promise<void> => {
		const roleDataMapping: any = {
			referee: 'referee_report_documents',
			league: 'league_report_documents',
			federation: 'federation_report_documents',
		};
		const data: any = {};
		data[roleDataMapping[report_type]] = report_documents;
		try {
			await axios.post(api.createMatchReports(id), data);
		} catch (err) {
			throw err;
		}
	},

	updateMatchResult: async (
		id: string,
		payload: {
			home: number | null;
			away: number | null;
		}
	): Promise<void> => {
		try {
			await axios.post(api.playCmd(), {
				matchId: id,
				homeResult: payload.home,
				awayResult: payload.away,
			});
		} catch (err) {
			throw err;
		}
	},

	updateMatchTime: async (id: string, payload: any): Promise<void> => {
		try {
			await axios.post(api.scheduleCmd(), {
				matchId: id,
				date: moment.utc(payload.date, 'DD/MM/YYYY').toISOString(),
				time: payload.time,
			});
		} catch (err) {
			throw err;
		}
	},

	generateNewStatement: async (payload: {
		statement_title: string;
		statement_date: string;
		startPagesFrom: number;
		selected_matches_ids: string[];
		competitionId: string;
	}): Promise<void> => {
		try {
			await axios.post(api.generateNewStatement(), payload);
		} catch (err) {
			throw err;
		}
	},

	getStatements: async ({ filters, ...params }: ApiQueryParams) => {
		try {
			const res = await axios.get(api.getStatements(), {
				params: { ...params, ...filters },
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	deleteStatement: async (_id: string) => {
		try {
			await axios.delete(api.deleteStatement(_id));
		} catch (err) {
			throw err;
		}
	},

	createFine: async (
		match: string,
		payload: {
			recipient: string;
			entity: EEntity;
			club_name: string;
			type?: FineType;
			amount?: number;
			suspension_step?: number;
			suspension_until?: string;
			description?: string;
			label?: string;
			ref_entity?: string;
			ref_club?: number;
			ref_match: string;
		}
	): Promise<any> => {
		try {
			const res = await axios.post(api.createFine(match), {
				...payload,
				entity_type: mapNewToOldRole(payload.entity),
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	updateFine: async (match: string, id: string, payload: any): Promise<any> => {
		try {
			const res = await axios.put(api.updateFine(match, id), payload);
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	removeFine: async (match: string, id: string): Promise<any> => {
		try {
			const res = await axios.delete(api.removeFine(match, id));
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	getFines: async (match: string): Promise<any> => {
		try {
			const res = await axios.get(api.getFines(match));
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	getPartialStatement: async (match: string): Promise<TPartialStatement> => {
		try {
			const res = await axios.post(api.getPartialStatement(match));
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	generateCurriculum: async (id: string): Promise<any> => {
		try {
			const res = await axios.post(api.generateCurriculum(), { id });
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	generateCurriculumClub: async (id: number): Promise<any> => {
		try {
			const res = await axios.post(api.generateCurriculumClub(), { id });
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	syncMatchesByStep: async (payload: {
		season: string;
		competitionId: string;
		roundId: string;
		stepId: string;
	}) => {
		try {
			await axios.post(api.syncMatchesByStep(payload.season, payload.stepId), {
				competitionId: payload.competitionId,
				roundId: payload.roundId,
			});
		} catch (err) {
			throw err;
		}
	},

	syncSingleClub: async (params: { season: string; organizationId: string }) => {
		try {
			await axios.post(api.syncSingleClub(), params);
		} catch (err) {
			throw err;
		}
	},

	getGroupCompetitors: async (_id: string): Promise<any> => {
		try {
			const res = await axios.get(api.getGroupCompetitors(_id));
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	getStaffs: async (params: TParams): Promise<Staff[]> => {
		try {
			const res = await axios.get(api.staffs(), {
				params: { ...params.filters, skip: params.skip, limit: params.limit },
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	getAthletes: async (params: TParams): Promise<Athlete[]> => {
		try {
			const res = await axios.get(api.athletes(), {
				params: { ...params.filters, skip: params.skip, limit: params.limit },
			});
			return res.data;
		} catch (err) {
			throw err;
		}
	},

	getPeople: async (filters: PeopleFilters): Promise<Person[]> => {
		const athletes = await service.getAthletes({ filters, skip: 0, limit: 10 });
		const staffs = await service.getStaffs({ filters, skip: 0, limit: 10 });
		const result: Person[] = [];

		for (const person of athletes) {
			result.push({
				goleeId: person.goleeId,
				name: person.name,
				surname: person.surname,
				role: 'GIOCATORE',
				matricola: person.registrationNumber,
				birthdate: person.birthdate,
				organizationIds: person.organizationIds,
				team: person.team,
			});
		}

		for (const person of staffs) {
			result.push({
				goleeId: person.goleeId,
				name: person.name,
				surname: person.surname,
				role: person.role,
				matricola: person.fiscalCode,
				birthdate: person.birthdate,
				organizationIds: [person.organizationId],
			});
		}

		return result;
	},
};

export const translationRole: any = {
	GIOCATORE: 'Giocatore',
	COLLABORATORE: 'Collaboratore',
	ALLENATORE: 'Allenatore',
	PREPARATORE_ATLETICO: 'Preparatore Atletico',
	DIRIGENTE: 'Dirigente',
	OPERATORE_SANITARIO: 'Operatore Sanitario',
};
export default service;

export type PeopleFilters = {
	organizationId?: string;
	searchName?: string;
	searchSurname?: string;
	role?: string;
};
type TParams = {
	filters: PeopleFilters;
	skip: number;
	limit: number;
};

const mapNewToOldRole = (newRole: EEntity) =>
	({
		GIOCATORE: 'athlete',
		CLUB: 'club',
		ALLENATORE: 'coach',
		COLLABORATORE: 'gencoll',
		DIRIGENTE: 'managerial',
		OPERATORE_SANITARIO: 'medical',
		PREPARATORE_ATLETICO: 'technical',
	}[newRole]);
