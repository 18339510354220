import { isValidDate } from '@gle/base-ui.inputs.input-date';
import * as Yup from 'yup';
import { FineType } from '../../../../../../../_types/enums';

export const validationSchema = Yup.object().shape({
	type: Yup.string().required('Dato obbligatorio'),
	entity: Yup.string().required('Dato obbligatorio'),
	recipient: Yup.string().required('Dato obbligatorio'),
	ref_club: Yup.number().when('entity', {
		is: 'CLUB',
		then: Yup.number().required('Dato obbligatorio'),
		otherwise: Yup.number().nullable(),
	}),
	ref_entity: Yup.string().when('entity', {
		is: 'CLUB',
		then: Yup.string().nullable(),
		otherwise: Yup.string().required('Dato obbligatorio'),
	}),
	amount: Yup.number().nullable(),
	suspension_step: Yup.number().when('type', {
		is: (value: FineType) =>
			value === FineType.red_card || value === FineType.suspension_no_red_card,
		then: Yup.number().required('Dato obbligatorio'),
		otherwise: Yup.number().nullable(),
	}),
	suspension_until: Yup.string()
		.nullable()
		.test('date', 'Data non valida', value =>
			value ? isValidDate(value, 'DD/MM/YYYY', true) : true
		),
	description: Yup.string().nullable(),
});
