import { Text } from '@gle/base-ui.typography.text';

type TProps = {
	club: {
		logo?: string;
		id?: number;
		name: string;
	};
};

const Component = ({ club }: TProps) => (
	<div className="club-card">
		<div className="logo-wrapper">
			<div className="logo" style={{ width: 50, display: 'flex', alignItems: 'center' }}>
				{club.logo && <img alt={club.name} src={club.logo} style={{ height: 25 }} />}
			</div>
		</div>
		<div className="club-name">
			<Text size="l" weight="medium">
				{club.name}
			</Text>
		</div>
	</div>
);

export default Component;
