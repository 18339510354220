import { RestDataContext, RestDataProvider } from '../../../providers';
import { MatchesService } from '../../../services';
import View from './view';
import { useFilters } from '../../../providers/FiltersProvider';

const Container = () => {
	const { selectedChampionshipGroup, onChampionshipChange } = useFilters();

	const onDelete = async (_id: string, context: any) => {
		try {
			await MatchesService.deleteStatement(_id);
			context.onRefresh();
		} catch (err) {}
	};

	return (
		<RestDataProvider
			api={{
				function: MatchesService.getStatements,
				params: {
					ref_championship: selectedChampionshipGroup.id,
				},
			}}>
			<RestDataContext.Consumer>
				{context => (
					<>
						<View
							data={context.data}
							loading={context.loading}
							filters={{ championshipId: selectedChampionshipGroup.id }}
							onFilter={filters => {
								context.onFilter?.(filters);
								filters.championshipId &&
									onChampionshipChange(filters.championshipId);
							}}
							onSearch={context.onSearch}
							onSort={context.onSort}
							onNew={context.onRefresh}
							pagination={context.pagination}
							onDelete={id => {
								onDelete(id, context);
							}}
						/>
					</>
				)}
			</RestDataContext.Consumer>
		</RestDataProvider>
	);
};

export default Container;
