const api = {
	getChampionship: (id: string) => `championship/${id}`,

	getMatches: () => `match`,
	getMatch: (id: string) => `match/${id}`,

	assignMatchJudges: (id: string) => `match/${id}/assign-judges`,
	createMatchReports: (id: string) => `match/${id}/reports`,
	updateMatchTime: (id: string) => `match/${id}/date`,

	playCmd: () => `matches/playCmd`,
	scheduleCmd: () => `matches/scheduleCmd`,

	createFine: (match: string) => `match/${match}/fines`,
	updateFine: (match: string, id: string) => `match/${match}/fines/${id}`,
	removeFine: (match: string, id: string) => `match/${match}/fines/${id}`,
	getFines: (match: string) => `fines/by-match/${match}`,

	generateNewStatement: () => `statement`,
	getStatements: () => `statement`,
	deleteStatement: (id: string) => `statement/${id}`,
	getPartialStatement: (match: string) => `statement/partial-match-data/${match}`,
	generateCurriculum: () => `statement/curriculum`,
	generateCurriculumClub: () => `statement/curriculum-club`,

	syncMatchesByStep: (season: string, stepId: string) =>
		`legapro-sync/season/${season}/step/${stepId}`,
	syncSingleClub: () => `legapro-people-sync/club`,
	getGroupCompetitors: (id: string) => `groups/${id}/competitors`,
	staffs() {
		return 'legapro-people/staffs';
	},
	athletes() {
		return 'legapro-people/athletes';
	},
};

export default api;
