import { Button } from '@gle/base-ui.buttons.button';
import { Text } from '@gle/base-ui.typography.text';
import { PaginationProps } from './types';
import { useEffect } from 'react';
import { Select } from '../../Inputs';

const Component = ({ ...props }: PaginationProps) => {
	useEffect(() => {}, [props.page]);
	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					gap: 10,
					marginTop: 10,
				}}>
				<Button onClick={props.onPrevPage} level="secondary" disabled={!props.canPrevPage}>
					Indietro
				</Button>

				{props.canChoosePage && (props.canPrevPage || props.canNextPage) ? (
					<Select
						name={'page'}
						minWidth={100}
						value={props.page}
						options={new Array(20)
							.fill('')
							.map((_, i) => ({ value: i + 1, label: `${i + 1}` }))}
						onChange={e => {
							props.onChoosePage && props.onChoosePage(Number(e.value));
						}}
					/>
				) : (
					<Text>{props.page}</Text>
				)}

				<Button onClick={props.onNextPage} level="secondary" disabled={!props.canNextPage}>
					Avanti
				</Button>
			</div>
		</>
	);
};

export default Component;
