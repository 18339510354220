import { Button } from '@gle/base-ui.buttons.button';
import { Heading } from '@gle/base-ui.typography.heading';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { InputDateField } from '../../../../../components/Inputs/DatePicker';
import { InputTimeField } from '../../../../../components/Inputs/TimePicker';
import Toast from '../../../../../components/Toast';
import { MatchesService } from '../../../../../services';
import { Match } from '../../../../../_types';

const Detail = ({ match }: { match?: Match }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmit = async (values: any) => {
		setIsLoading(true);

		try {
			await MatchesService.updateMatchTime(match!.id, {
				...values,
			});

			Toast.success('Dati aggiornati correttamente');
		} catch (err) {
			console.log(err);
			Toast.error('Si è verificato un errore');
		} finally {
			setIsLoading(false);
		}
	};

	if (!match) return <></>;
	return (
		<div>
			<Heading level={4} style={{ marginBottom: 10 }}>
				Dettagli
			</Heading>
			<Formik
				enableReinitialize
				initialValues={{
					date: match.date,
					time: match.time,
				}}
				validationSchema={Yup.object().shape({
					date: Yup.string().required('Data obbligatoria'),
					time: Yup.string().required('Orario obbligatorio'),
				})}
				validateOnMount={false}
				validateOnBlur={false}
				onSubmit={onSubmit}>
				{formContext => {
					return (
						<Form>
							<div className="flex gap-20 ">
								<InputDateField name="date" label="Data" />
								<InputTimeField name="time" label="Orario" />
							</div>

							<div className="flex justify-between mt-20">
								<div className="flex gap-20">
									<Button
										size="s"
										level="tertiary"
										onClick={() => formContext.resetForm()}
										disabled={isLoading}>
										Annulla
									</Button>
									<Button size="s" type="submit" isLoading={isLoading}>
										Salva
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export { Detail };
