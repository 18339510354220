import { ApolloError } from '@apollo/client';
import React, { ReactNode, useEffect, useState } from 'react';
import { Fine } from '../../../../../_types';
import { MatchesService } from '../../../../../services';

export type TFinesContext = {
	fines: Fine[];
	loading: boolean;
	reloadFines: () => void;
};

export const FinesContext = React.createContext<TFinesContext>({
	fines: [],
	loading: false,
	reloadFines: () => {},
});

function FinesProvider({
	matchId,
	children,
	onError,
}: {
	matchId: string;
	children: ReactNode;
	onError?: (error?: ApolloError) => void;
}) {
	const [fines, setFines] = useState<Fine[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>();

	useEffect(() => {
		loadFines();
	}, []);

	const loadFines = async () => {
		setLoading(true);
		try {
			setFines(await MatchesService.getFines(matchId));
		} catch (err) {
			console.log('Error retrieving fines');
			setError(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (error) {
			onError?.(error);
		}
	}, [error]);

	return (
		<FinesContext.Provider
			value={{
				fines,
				loading,
				reloadFines: () => {
					loadFines();
				},
			}}>
			{children}
		</FinesContext.Provider>
	);
}

function useFinesContext() {
	const context = React.useContext(FinesContext);
	if (context === undefined) {
		throw new Error('useMatchDetailContext must be used within a MatchDetailProvider');
	}

	return context;
}

export { FinesProvider, useFinesContext };
