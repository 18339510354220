import { Group } from '../../redux/Championship/reducer';
import { Select } from '../Inputs';

const StepSelector = ({
	onChange,
	isClearable,
	value,
	stepGroups,
}: {
	onChange: (id?: string) => void;
	value: string;
	isClearable?: boolean;
	stepGroups?: Group[];
}) => {
	const getOptions = () => {
		return (
			stepGroups?.map(t => ({
				value: t.id,
				label: t.label,
			})) || []
		);
	};

	return (
		<Select
			placeholder="Giornata"
			onChange={e => onChange(e?.value)}
			value={value}
			options={getOptions()}
			isClearable={isClearable}
		/>
	);
};

export { StepSelector };
