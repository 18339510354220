import { Button } from '@gle/base-ui.buttons.button';
import { CircleButton } from '@gle/base-ui.buttons.circle-button';
import { Tooltip } from '@gle/base-ui.tooltip';
import { useEffect, useRef, useState } from 'react';
import Toast from '../../Toast';
import { PepaInterceptor } from '../../../services';

type Props = {
	/**
	 * Icon of the button.
	 */
	icon?: React.ReactNode;

	/**
	 * Level of the button.
	 */
	level?: 'primary' | 'secondary' | 'tertiary';

	/**
	 * Color of the button.
	 */
	color?: 'primary' | 'accent';

	/**
	 * Label of the button.
	 */
	label?: string;

	/**
	 * Callback.
	 */
	onUploadCompleted: (file: File, url: string) => void;
};

const Component = ({
	icon = <i className="las la-upload"></i>,
	level = 'primary',
	color = 'primary',
	label = 'Carica',
	...props
}: Props) => {
	const [isUploading, setIsUploading] = useState<boolean>(false);

	const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			uploadFile(event.target.files[0]);
		}
	};

	const uploadFile = async (file: File) => {
		setIsUploading(true);
		try {
			let formData = new FormData();
			formData.append('file', file);
			const { data } = await PepaInterceptor.post(
				`${window._env_.REACT_APP_API_URL_PEPA_SERVICE}files`,
				formData
			);
			props.onUploadCompleted(file, data.public_url);
			inputRef.current.value = '';
		} catch (err) {
			console.error(err);
			Toast.error('Impossibile caricare il file.');
		} finally {
			setIsUploading(false);
		}
	};

	const uploadButton = () => {
		return (
			<Button
				level={level}
				color={color}
				isLoading={isUploading}
				onClick={() => {
					inputRef.current.click();
				}}>
				{label}
				{!isUploading && icon}
			</Button>
		);
	};

	const inputRef: any = useRef();

	return (
		<>
			<input
				name="file-upload"
				accept="application/pdf"
				type="file"
				onChange={onFileChange}
				ref={inputRef}
				style={{ display: 'none', visibility: 'hidden' }}
			/>
			<>{uploadButton()}</>
		</>
	);
};

export default Component;
