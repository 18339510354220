import { gql, useLazyQuery } from '@apollo/client';
import { Loader } from '@gle/base-ui.loader';
import React, { useContext, useEffect, useState } from 'react';
import { Group } from '../redux/Championship/reducer';

const GroupContext = React.createContext<{
	championshipGroups: Group[];
	getRoundGroups: (championshipId: string) => Group[];
	getStepGroups: (championshipId: string, roundId: string) => Group[];
}>({
	championshipGroups: [],
	getRoundGroups: () => [],
	getStepGroups: () => [],
});

const query = gql`
	query GetMatchGroup($groupId: String!) {
		getMatchGroup(id: $groupId) {
			id
			label
			type
			subGroups {
				id
				label
				type
				subGroups {
					id
					label
					type
					subGroups {
						id
						label
						type
					}
				}
			}
		}
	}
`;

export const GroupProvider = ({ children }: any) => {
	const groupId = 'd1ed33dd-9cdc-4412-8cd4-51b65897b384'; // TODO: retrieve from limitations
	const [championshipGroups, setChampionshipGroups] = useState<Group[]>([]);

	const [loadGroupsFromApi, { data, loading }] = useLazyQuery<{ getMatchGroup: Group }>(query, {
		variables: { groupId },
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		loadGroupsFromApi();
	}, []);

	useEffect(() => {
		const groups = data?.getMatchGroup?.subGroups;
		if (groups?.length) {
			setChampionshipGroups(groups);
		}
	}, [data]);

	const getRoundGroups = (championshipId: string) => {
		return championshipGroups.find(g => g.id === championshipId)?.subGroups || [];
	};
	const getStepGroups = (championshipId: string, roundId: string) => {
		return getRoundGroups(championshipId).find(g => g.id === roundId)?.subGroups || [];
	};

	return (
		<GroupContext.Provider
			value={{
				championshipGroups,
				getRoundGroups,
				getStepGroups,
			}}>
			<>
				{!loading && championshipGroups.length ? (
					children
				) : (
					<div className="flex items-center justify-center full-height">
						<Loader size="l" />
					</div>
				)}
			</>
		</GroupContext.Provider>
	);
};

export const useGroup = () => {
	const context = useContext(GroupContext);

	if (!context) {
		throw new Error('useGroup must be used within GroupProvider');
	}

	return context;
};
