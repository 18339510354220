import { Chip } from '@gle/base-ui.typography.chip';
import { EEntity } from '../../services/People/types';
import { translationRole } from '../../services/Matches/service';

const RoleChip = ({ role }: { role: EEntity }) => {
	const getChip = () => {
		switch (role) {
			case 'GIOCATORE': {
				return (
					<Chip color="gray" size="s">
						{translationRole.GIOCATORE}
					</Chip>
				);
			}
			case 'ALLENATORE': {
				return (
					<Chip color="warning" size="s">
						{translationRole.ALLENATORE}
					</Chip>
				);
			}
			case 'PREPARATORE_ATLETICO': {
				return (
					<Chip color="success" size="s">
						{translationRole.PREPARATORE_ATLETICO}
					</Chip>
				);
			}
			case 'OPERATORE_SANITARIO': {
				return (
					<Chip color="primary" size="s">
						{translationRole.OPERATORE_SANITARIO}
					</Chip>
				);
			}
			case 'DIRIGENTE': {
				return (
					<Chip color="danger" size="s">
						{translationRole.DIRIGENTE}
					</Chip>
				);
			}
			case 'CLUB': {
				return (
					<Chip color="accent" size="s">
						{'Club'}
					</Chip>
				);
			}
			default: {
				return <Chip size="s">{role}</Chip>;
			}
		}
	};

	return getChip();
};

export { RoleChip };
