import styled from 'styled-components';
import Settings from './settings';
import { AppState } from '../../redux/types';
import { useSelector } from 'react-redux';
import { Tooltip } from '@gle/base-ui.tooltip';
import Menu from '../Sidebar/menu';

type TProps = {};

export const TOOLBAR_HEIGHT = 50;
const logoSize = '30px';

const Wrapper = styled.div`
	grid-column: span 2;
	background-color: var(--primary-dark-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	font-size: 14px;
	color: var(--white-color);
	-webkit-box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 15%);
	box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 15%);

	.left,
	.right {
		float: none;
		display: flex;
		align-items: center;
	}

	.left {
		gap: 40px;

		.title {
			font-weight: 600;
			font-size: 14px;
			margin: 0;
			text-transform: capitalize;
		}

		#mobile-menu-toggle {
			@media screen and (min-width: 769px) {
				display: none;
			}

			cursor: pointer;
		}
	}

	.right {
		gap: 15px;
		> div {
			cursor: pointer;
		}
	}

	i {
		font-size: 26px;
	}

	.header {
		display: flex;
		align-items: center;
		gap: 10px;
		img {
			width: 50px;
		}
	}
`;

const logo = `${window._env_.PUBLIC_URL}/images/logo.png`;
const goleeLogo = `${window._env_.PUBLIC_URL}/images/golee_white.png`;

const Toolbar = ({ ...props }: TProps) => {
	const auth = useSelector((store: AppState) => store.Auth);

	const title = (): string => {
		return auth.profile.name || '';
	};

	return (
		<>
			<Wrapper>
				<div className="left">
					<div className="header">
						<img className="logo" src={logo} alt="Lega Pro" style={{ width: 30 }} />
						<img className="logo sm-hidden" src={goleeLogo} alt="Golee" style={{ width: 50 }} />
					</div>

					<Menu />
				</div>

				<div className="right">
					<div className="sm-hidden">
						<Tooltip content={title()}>
							<i className="las la-user"></i>
						</Tooltip>
					</div>
					<div>
						<Settings />
					</div>
				</div>
			</Wrapper>
		</>
	);
};

export default Toolbar;
