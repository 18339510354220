import { CircleButton } from '@gle/base-ui.buttons.circle-button';
import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import { IconX } from '@golee/gle-icons';
import Toast from '../../../../../components/Toast';
import { MatchesService } from '../../../../../services';
import { currencyFormatter } from '../../../../../utils/currency';
import { Fine, Match } from '../../../../../_types';
import { FineIcon } from './persons-club-list/FinesForm/FineIcon';
import { FineTypes } from './persons-club-list/FinesForm/types';
import { PrintPartialStatementButton } from './PrintPartialStatementButton';

const FinesSummary = ({
	match,
	fines,
	onChangeFines,
}: {
	match?: Match;
	fines: Fine[];
	onChangeFines?: () => void;
}) => {
	const onDelete = async (fine: Fine) => {
		if (!fine) return;

		try {
			await MatchesService.removeFine(match!.id, fine._id);
			Toast.success('Sanzione eliminata');
			onChangeFines && onChangeFines();
		} catch (err) {
			console.log(err);
			Toast.error("Impossibile eliminare l'sanzione");
		}
	};

	if (!match) return <></>;
	return (
		<div>
			<div className={'flex justify-between'}>
				<Heading level={4} style={{ marginBottom: 20 }}>
					Verbalino parziale
				</Heading>

				<PrintPartialStatementButton isVisible={!!fines.length} match={match} />
			</div>

			{fines?.map(fine => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						gap: 5,
						marginBottom: 2,
					}}>
					<div style={{ width: 200 }}>{fine.recipient}</div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 3, width: 220 }}>
						<FineIcon fine={fine} />
						<Text size="s">
							{FineTypes.find(t => t.value === fine.type)?.label}

							{fine.amount ? <> ({currencyFormatter.format(fine.amount)})</> : <></>}

							{fine.suspension_step ? <> ({fine.suspension_step} g)</> : <></>}
						</Text>
					</div>
					<CircleButton color="danger" size="s" onClick={() => onDelete(fine)}>
						<IconX />
					</CircleButton>
				</div>
			))}
		</div>
	);
};

export { FinesSummary };
