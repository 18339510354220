import { ETeam, Fine, Person } from '../../../../../../_types';
import { Column } from '../../../../../../components/Table/Table';
import { Tooltip } from '@gle/base-ui.tooltip';
import { Button } from '@gle/base-ui.buttons.button';
import { RoleChip } from '../../../../../../components/RoleChip';
import { Text } from '@gle/base-ui.typography.text';
import { FineIcon } from './FinesForm/FineIcon';
import { FineType } from '../../../../../../_types/enums';

export const getColumns = (
	onCurriculumClick: (person: Person) => void,
	onYellowCardAdded: (person: Person) => void,
	onAmendClicked: (person: Person) => void,
	onClickPersonFine: (person: Person, fine: Fine) => void,
	fines?: Fine[]
): Column<Person>[] => [
	{
		key: 'name',
		name: 'Nome',
		width: 290,
		formatter: ({ row: person }) => {
			return (
				<div className={'flex gap-20 items-center'} style={{ height: '100%' }}>
					<Tooltip content={'Vai a curriculum'}>
						<Button level="tertiary" size="s" onClick={() => onCurriculumClick(person)}>
							<i className="las la-id-card" />
						</Button>
					</Tooltip>
					<div>
						<Text weight={'medium'}>
							{person.surname} {person.name}
						</Text>
						<Text size="xs">{person.birthdate}</Text>
					</div>
				</div>
			);
		},
	},
	{
		key: 'role',
		name: 'Ruolo',
		width: 170,
		formatter: ({ row: person }) => {
			return (
				<>
					<RoleChip role={person.role} />
				</>
			);
		},
	},
	{
		key: 'identify',
		name: 'Identificativo',
		cellClass: 'flex items-center justify-start',
		maxWidth: 300,
		formatter: ({ row: person }) => {
			const translationTeam: any = {
				[ETeam.FIRST_TEAM]: 'Prima squadra',
				[ETeam.YOUTH_TEAM]: 'Primavera',
				[ETeam.REGISTERED]: 'Tesserato',
			};
			return (
				<div>
					<Text size="s">{person.matricola}</Text>
					<Text size="s">
						{person.role === 'GIOCATORE' && person.team
							? translationTeam[person.team]
							: 'Staff'}
					</Text>
				</div>
			);
		},
	},
	{
		key: 'status',
		name: 'Sanzioni',
		width: 100,
		formatter: ({ row: person }) => {
			return <>{renderPersonFines(person, onClickPersonFine, fines)}</>;
		},
	},
	{
		key: 'actions',
		name: 'Azioni',
		cellClass: 'flex items-center gap-10',
		formatter: ({ row: person }) => {
			return (
				<div className={'flex gap-10'}>
					<Button
						color="danger"
						level="tertiary"
						size="s"
						onClick={() => onAmendClicked(person)}>
						Sanziona
					</Button>
					{!hasYellowCard(person, fines) && (
						<Button
							color="warning"
							level="tertiary"
							size="s"
							onClick={() => onYellowCardAdded(person)}>
							Ammonisci
						</Button>
					)}
				</div>
			);
		},
	},
];

const renderPersonFines = (
	person: Person,
	onClickPersonFine: (person: Person, fine: Fine) => void,
	fines?: Fine[]
) => {
	const _fines = getPersonFines(person, fines);

	if (!_fines.length) {
		return '-';
	}

	return _fines.map(fine => (
		<FineIcon fine={fine} onClick={() => onClickPersonFine(person, fine)} />
	));
};

const hasYellowCard = (person: Person, fines?: Fine[]) => {
	return getPersonFines(person, fines)
		.map(t => t.type)
		.includes(FineType.yellow_card);
};

const getPersonFines = (person: Person, fines?: Fine[]) => {
	if (!fines || !fines.length) {
		return [];
	}

	return fines.filter(fine => fine.ref_entity === person.goleeId) || [];
};
