import { Loader } from '@gle/base-ui.loader';
import { ERole } from '@golee/gle-types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import DataGrid, { Column as RDGColumn } from 'react-data-grid';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 20px;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s;
	}

	&.scrollable {
		&::after {
			opacity: 1;
			visibility: visible;
		}
	}

	.rdg-cell[aria-selected='true'] {
		box-shadow: none !important;
	}

	&.row-clickable {
		.rdg-row {
			cursor: pointer;
		}
	}

	.rdg-row {
		> .rdg-cell {
			border-color: #eee;
			&:last-child {
				border-right: none;
			}
		}
	}

	.rdg-header-row {
		background: #fff;
		font-size: var(--s-size);

		> .rdg-cell {
			border-color: #eee;
			color: var(--gray-color);
			font-weight: var(--font-weight-medium);

			&:last-child {
				border-right: none;
			}
		}
	}

	.rdg {
	}
`;

export type Column<T> = RDGColumn<T> & {
	allowedRoles?: ERole[];
};

type Props = {
	data: any[];
	isLoading?: boolean;
	columns: Column<any>[];
	onRowClick?: (row: any, cell: any) => void;
};

export const TABLE_ROW_HEIGHT = 50;

export const filterColumnsByRole = (columns: Column<any>[], role?: ERole) => {
	if (!role) {
		return columns;
	}

	return _.filter(columns, column => {
		return !column.allowedRoles || column.allowedRoles.includes(role);
	});
};

const Component = ({ data, columns, onRowClick, isLoading }: Props) => {
	const [showScrollShadow, setShowScrollShadow] = useState<boolean>(false);

	const calcHeight = () => {
		const scrollBarHeight = 5;
		return (data.length + 1) * TABLE_ROW_HEIGHT + scrollBarHeight;
	};

	const checkXScroll = () => {
		try {
			const el = document.getElementsByClassName('rdg-table')[0];

			if (el) {
				setShowScrollShadow(el.scrollWidth > el.clientWidth);
				el.addEventListener('scroll', () => {
					setShowScrollShadow(el.scrollWidth - el.clientWidth !== el.scrollLeft);
				});
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (columns && columns.length && data && data.length) {
			setTimeout(() => {
				checkXScroll();
			}, 100);
		}
	}, [columns, data]);

	useEffect(() => {
		window.addEventListener('resize', checkXScroll);

		return () => {
			window.removeEventListener('resize', checkXScroll);
		};
	}, []);

	return (
		<Wrapper
			className={`${showScrollShadow ? 'scrollable' : ''} ${
				onRowClick ? 'row-clickable' : ''
			}`}>
			{isLoading ? (
				<div
					style={{
						height: 200,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Loader size="m" />
				</div>
			) : (
				<DataGrid
					columns={columns}
					rows={data}
					rowKeyGetter={row => (row.id ? row.id : row._id)}
					rowHeight={TABLE_ROW_HEIGHT}
					onRowClick={onRowClick}
					headerRowHeight={TABLE_ROW_HEIGHT}
					style={{
						height: calcHeight(),
						backgroundColor: 'none',
						border: 'none',
						fontSize: 'var(--m-size)',
					}}
					className="rdg-light rdg-table"
				/>
			)}
		</Wrapper>
	);
};

export default Component;
