import { Tooltip } from '@gle/base-ui.tooltip';
import {
	DoubleYellowCardIcon,
	EuroFineIcon,
	InhibitionIcon,
	RedCardIcon,
	SuspensionNoRedCardIcon,
	YellowCardIcon,
} from '../../../../../../../components/Icons';
import { Fine } from '../../../../../../../_types';
import { FineType } from '../../../../../../../_types/enums';
import { FineTypes } from './types';

export const FineIcon = ({ fine, ...props }: { fine: Fine; onClick?: () => void }) => {
	const getIcon = () => {
		switch (fine.type) {
			case FineType.yellow_card: {
				return <YellowCardIcon />;
			}
			case FineType.double_yellow_card: {
				return <DoubleYellowCardIcon />;
			}
			case FineType.red_card: {
				return <RedCardIcon />;
			}
			case FineType.fine: {
				return <EuroFineIcon />;
			}
			case FineType.suspension_no_red_card: {
				return <SuspensionNoRedCardIcon />;
			}
			case FineType.inhibition_with_expulsion:
			case FineType.inhibition_without_expulsion: {
				return <InhibitionIcon />;
			}
		}
	};

	const getTooltipContent = () => {
		if (fine.label) {
			return fine.label;
		}

		return FineTypes.find(t => t.value === fine.type)?.label;
	};

	return (
		<Tooltip content={getTooltipContent()}>
			<span
				onClick={props.onClick}
				style={{
					borderRadius: 5,
					marginRight: 2,
					cursor: 'pointer',
					width: 20,
					height: 20,
					display: 'inline-block',
				}}>
				{getIcon()}
			</span>
		</Tooltip>
	);
};
