import { ReactNode } from 'react';
import { Card } from '@gle/base-ui.surfaces.card';
import { RoleChip } from '../../components/RoleChip';
import { Text } from '@gle/base-ui.typography.text';
import { Button } from '@gle/base-ui.buttons.button';
import { IconPrint } from '@golee/gle-icons';
import { Person } from '../../_types';
import { useCurriculum } from './useCurriculum';
import { EEntity } from '../../services/People/types';
import { Organization, useOrganizations } from '../../providers/OrganizationsProvider';

export const CardSectionClub = ({ clubs }: { clubs: Organization[] }) => {
	const { generateClub, isLoading: isLoadingCurriculum } = useCurriculum();
	const onSubmitClub = async (club: Organization) => {
		const url = await generateClub({
			id: Number(club.id),
			extra: {
				...club,
				matricola: club.federationNumber,
			},
		});

		window.open(url, '_blank');
	};

	return (
		<div style={gridDisplayStyle}>
			{clubs.map(club => (
				<CustomCard
					role={'CLUB'}
					matricola={club.federationNumber}
					title={club.name}
					onSubmit={() => onSubmitClub(club)}
					isLoading={isLoadingCurriculum}
				/>
			))}
		</div>
	);
};

export const CardSectionPeople = ({ people }: { people: Person[] }) => {
	const { generatePerson, isLoading: isLoadingCurriculum } = useCurriculum();
	const { getNameByOrganizationId } = useOrganizations();

	const onSubmitPeople = async (person: Person) => {
		const url = await generatePerson({
			id: person.goleeId,
			extra: person,
		});

		window.open(url, '_blank');
	};

	return (
		<div style={gridDisplayStyle}>
			{people.map(person => (
				<CustomCard
					role={person.role}
					matricola={person.matricola || '-'}
					title={`${person.surname} ${person.name}`}
					onSubmit={() => onSubmitPeople(person)}
					isLoading={isLoadingCurriculum}>
					<>
						{person.organizationIds.map(organizationId => (
							<Text size="s">Club: {getNameByOrganizationId(organizationId)}</Text>
						))}
						<Text size="s">Data di nascita: {person.birthdate}</Text>
					</>
				</CustomCard>
			))}
		</div>
	);
};

const CustomCard = (props: {
	role: EEntity;
	matricola: string;
	title: string;
	isLoading: boolean;
	onSubmit: () => void;
	children?: ReactNode;
}) => {
	return (
		<Card>
			<RoleChip role={props.role} />
			<Text weight="medium" style={{ marginBlock: 10 }}>
				{props.title}
			</Text>
			{props.children}
			<div style={{ marginTop: 40 }}>
				<Button isLoading={props.isLoading} onClick={props.onSubmit}>
					Stampa curriculum sportivo <IconPrint />
				</Button>
			</div>
		</Card>
	);
};

const gridDisplayStyle = {
	marginTop: 20,
	display: 'grid',
	gap: 30,
	gridTemplateColumns: 'repeat(5, 1fr)',
};
