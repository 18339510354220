export const YellowCardIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" fill="#FFCC41" />
		</svg>
	);
};

export const DoubleYellowCardIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" fill="#FFCC41" />
			<path d="M48 0.5L0.5 48H48V0.5Z" fill="#D92121" />
		</svg>
	);
};

export const RedCardIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" fill="#D92121" />
		</svg>
	);
};

export const SuspensionNoRedCardIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="1.5" y="1.5" width="45" height="45" stroke="#D92121" stroke-width="3" />
			<line
				x1="35.3294"
				y1="14.1155"
				x2="14.1266"
				y2="35.3391"
				stroke="#D92121"
				stroke-width="5"
			/>
			<line
				x1="13.4095"
				y1="13.8036"
				x2="34.633"
				y2="35.0065"
				stroke="#D92121"
				stroke-width="5"
			/>
		</svg>
	);
};

export const GenericFine = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" fill="#4D98C2" />
			<path
				d="M26.0399 11.5455L25.7168 29.6793H22.2739L21.9508 11.5455H26.0399ZM24.0016 37.2362C23.3138 37.2362 22.7255 36.9959 22.2366 36.5153C21.7478 36.0264 21.5075 35.4381 21.5158 34.7504C21.5075 34.0709 21.7478 33.4909 22.2366 33.0103C22.7255 32.5214 23.3138 32.277 24.0016 32.277C24.6727 32.277 25.2527 32.5214 25.7416 33.0103C26.2305 33.4909 26.4791 34.0709 26.4873 34.7504C26.4791 35.2061 26.3589 35.6245 26.1269 36.0057C25.9032 36.3786 25.6049 36.6768 25.232 36.9006C24.8592 37.1243 24.449 37.2362 24.0016 37.2362Z"
				fill="white"
			/>
		</svg>
	);
};

export const EuroFineIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" fill="#6750A7" />
			<path
				d="M30.6992 20.4695L29.5185 23.0298H13.1744L14.1065 20.4695H30.6992ZM28.4744 25.5156L27.2688 28.1009H13.1744L14.1065 25.5156H28.4744ZM33.7319 13.7205L32.2653 16.8651C31.8013 16.4673 31.2958 16.1069 30.7489 15.7837C30.2021 15.4606 29.6138 15.2037 28.984 15.0131C28.3543 14.8143 27.679 14.7148 26.9581 14.7148C25.6323 14.7148 24.4516 15.0711 23.4158 15.7837C22.3801 16.4963 21.5639 17.5652 20.9673 18.9904C20.379 20.4073 20.0849 22.1722 20.0849 24.2852C20.0849 26.3981 20.379 28.163 20.9673 29.5799C21.5639 30.9968 22.3801 32.0616 23.4158 32.7741C24.4516 33.4785 25.6323 33.8306 26.9581 33.8306C27.6873 33.8306 28.3667 33.7353 28.9964 33.5447C29.6345 33.3542 30.2145 33.1056 30.7365 32.799C31.2668 32.4841 31.7433 32.1527 32.1658 31.8047L33.6697 34.9368C32.7914 35.7322 31.7723 36.333 30.6122 36.739C29.4522 37.145 28.2341 37.348 26.9581 37.348C24.8783 37.348 23.0347 36.8219 21.4272 35.7695C19.828 34.7172 18.5727 33.2174 17.6612 31.2702C16.7498 29.3147 16.294 26.9864 16.294 24.2852C16.294 21.5756 16.7498 19.2431 17.6612 17.2876C18.5727 15.3321 19.828 13.8282 21.4272 12.7759C23.0347 11.7236 24.8783 11.1974 26.9581 11.1974C28.2839 11.1974 29.5185 11.417 30.6619 11.8562C31.8054 12.287 32.8287 12.9085 33.7319 13.7205Z"
				fill="white"
			/>
		</svg>
	);
};

export const InhibitionIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" fill="#262626" />
			<path
				d="M31.955 31.955C36.3483 27.5616 36.3483 20.4384 31.955 16.045C27.5616 11.6517 20.4384 11.6517 16.045 16.045M31.955 31.955C27.5616 36.3483 20.4384 36.3483 16.045 31.955C11.6517 27.5616 11.6517 20.4384 16.045 16.045M31.955 31.955L16.045 16.045"
				stroke="white"
				stroke-width="3"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
