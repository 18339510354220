import { AppState } from '@auth0/auth0-react';
import { Text } from '@gle/base-ui.typography.text';
import { ERole } from '@golee/gle-types';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { EPath } from '../../router/types/path.enum';
import { RoleWrapper } from '../RoleWrapper';

const Wrapper = styled.div``;

export const MenuParent = styled.ul`
	margin: 0 0 0 0;
	list-style: none;
	padding: 0;
	display: flex;
	gap: 20px;
`;

export const MenuChild = styled.li`
	&.selected {
		> a {
			> p {
				color: var(--text-on-primary-color) !important;
			}
			background-color: var(--primary-darker-color);
		}
	}

	&:not(.selected) {
		a {
			> p {
				color: var(--primary-lightest-color) !important;
			}
		}

		&:hover {
			> a {
				background-color: var(--accent-color-light);
			}
		}
	}
`;

export const MenuLink = styled.a`
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 5px;
	display: inline-block;
`;

const Menu = () => {
	const dispatch = useDispatch();

	const { pathname } = useSelector((store: AppState) => store.router.location);

	const onNavigate = (path: string) => {
		if (pathname !== path) {
			dispatch(push(path));
		}
	};

	const isSelected = (path: string, exact: boolean = true) => {
		if (exact) {
			return path === pathname;
		}

		return pathname.includes(path);
	};

	return (
		<Wrapper>
			<MenuParent>
				<MenuChild className={`${isSelected(EPath.matches) ? 'selected' : ''}`}>
					<MenuLink onClick={() => onNavigate(EPath.matches)}>
						<Text>Partite</Text>
					</MenuLink>
				</MenuChild>

				<RoleWrapper allowedRoles={[ERole.judge]}>
					<MenuChild
						className={`${
							isSelected(EPath.statements) || isSelected(EPath.statements_generate)
								? 'selected'
								: ''
						}`}>
						<MenuLink onClick={() => onNavigate(EPath.statements)}>
							<Text>Comunicati</Text>
						</MenuLink>
					</MenuChild>
				</RoleWrapper>

				<RoleWrapper allowedRoles={[ERole.judge]}>
					<MenuChild className={`${isSelected(EPath.curriculum) ? 'selected' : ''}`}>
						<MenuLink onClick={() => onNavigate(EPath.curriculum)}>
							<Text>Curriculum sportivi</Text>
						</MenuLink>
					</MenuChild>
				</RoleWrapper>

				<RoleWrapper allowedRoles={[ERole.judge]}>
					<MenuChild className={`${isSelected(EPath.sync) ? 'selected' : ''}`}>
						<MenuLink onClick={() => onNavigate(EPath.sync)}>
							<Text>Sincronizza dati</Text>
						</MenuLink>
					</MenuChild>
				</RoleWrapper>
			</MenuParent>
		</Wrapper>
	);
};
export default Menu;
