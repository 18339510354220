import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import { ERole } from '@golee/gle-types';
import { ClubsHeader } from '../../../../../components/ClubsHeader';
import { ResultForm } from '../../../../../components/Forms';
import { RoleWrapper } from '../../../../../components/RoleWrapper';
import { Match, MatchGroupType } from '../../../../../_types';

const MatchResult = ({ match }: { match?: Match }) => {
	if (!match) return <></>;
	return (
		<div>
			<Heading level={3} style={{ marginBottom: 10 }}>
				Partita
			</Heading>
			<Text size="s" color="gray" className="mb-20">
				{match.groups.find(g => g.type === MatchGroupType.competition)?.label || '-'}
				<br />
				{match.groups.find(g => g.type === MatchGroupType.round)?.label || '-'},{' '}
				{match.groups.find(g => g.type === MatchGroupType.step)?.label || '-'}
			</Text>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<ClubsHeader match={match} />
				<RoleWrapper allowedRoles={[ERole.judge]}>
					<ResultForm match={match} />
				</RoleWrapper>
			</div>
		</div>
	);
};

export { MatchResult };
