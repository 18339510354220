import { Tooltip } from '@gle/base-ui.tooltip';
import { Semaphore as GleSemaphore } from '@gle/base-ui.semaphore';

type TProps = {
	style?: React.CSSProperties;
	type?: 'success' | 'warning' | 'danger';
	size?: 's' | 'm' | 'l' | number;
	tooltip?: string;
};

const Semaphore = ({ style = {}, size = 's', ...props }: TProps) => {
	return (
		<>
			<Tooltip content={props.tooltip} visible={!props.tooltip ? false : undefined}>
				<GleSemaphore color={props.type} size={size} style={style} />
			</Tooltip>
		</>
	);
};

export default Semaphore;
