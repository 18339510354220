import { EPath } from './types/path.enum';
import { EScopes } from './types/scopes.enum';

const routes = [
	{
		path: EPath.matches,
		scope: EScopes.matches,
	},
	{
		path: EPath.statements,
		scope: EScopes.statements,
	},
	{
		path: EPath.statements_generate,
		scope: EScopes.statements,
	},
	{
		path: EPath.curriculum,
		scope: EScopes.search,
	},
	{
		path: EPath.sync,
		scope: EScopes.sync,
	},
];

export default routes;
