import { ErrorMessage, Field, useField } from 'formik';
import styled from 'styled-components';

type Props = {
	/**
	 * The component to render.
	 */
	as?: 'input' | 'textarea';

	/**
	 * HTML input type.
	 */
	type?: string;

	/**
	 * Name of the field (same of the form value).
	 */
	name: string;

	/**
	 * Placeholder of the field.
	 */
	placeholder?: string;

	/**
	 * Label of the field.
	 */
	label?: string;

	/**
	 * Min value, for number inputs.
	 */
	min?: number;
};

const Wrapper = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	&.h-auto {
		height: auto;
	}

	label {
		font-size: var(--s-size);
		display: block;
		color: var(--gray-light-color);
	}

	input,
	textarea,
	select {
		box-sizing: border-box;
	}

	input,
	textarea {
		width: 100%;
		padding: 0 7px;
		border: 1px solid var(--gray-lightest-color);
		border-radius: 4px;
		appearance: none;
		outline: none;
		background: transparent;
		height: 38px;
		font-size: var(--m-size);
		font-family: var(--font-family);
	}
	textarea {
		resize: vertical;
		min-height: 200px;
		max-height: 500px;
		padding-top: 7px;
	}

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}
		input {
			border-color: var(--danger-color);
		}
	}
`;

const Component = ({ ...props }: Props) => {
	const [field, meta] = useField(props);

	return (
		<Wrapper
			className={`${meta.touched && meta.error ? 'error' : ''} ${
				props.as === 'textarea' ? 'h-auto' : ''
			}`}>
			{props.label && <label htmlFor={props.name}>{props.label}</label>}
			<Field {...props} {...field} />
			<ErrorMessage name={props.name} component="div" className="form-field-error" />
		</Wrapper>
	);
};

export default Component;
