import { gql } from '@apollo/client';
import { push } from 'connected-react-router';
import flat from 'flat';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Toast from '../../../components/Toast';
import { GraphQLDataContext, GraphQLDataProvider } from '../../../providers';
import { EPath } from '../../../router/types/path.enum';
import { MatchesService } from '../../../services';
import { TOnSubmit } from './types';
import { View } from './view';
import { useFilters } from '../../../providers/FiltersProvider';

function getMatchesFromContextData(data: any & { data: any }) {
	return data && data.getMatches && data.getMatches.length
		? data.getMatches.map((t: any) => flat(t))
		: [];
}

function getIsSelectAllActive(data: any[], selectedMatches: any[]): boolean {
	return data && data.length
		? data.every((currentMatch: any) => {
				return selectedMatches.find(match => match.id === currentMatch.id);
		  })
		: false;
}

function isCompetitionFilterChanged(filters: { [p: string]: any }) {
	return (
		filters.competitionId &&
		!filters.roundId &&
		!filters.stepId &&
		!filters.club &&
		!filters.date
	);
}

const Container = () => {
	const { selectedChampionshipGroup, selectedRoundGroup, selectedStepGroup } = useFilters();
	const [selectedMatches, setSelectedMatches] = useState<any[]>([]);
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
	const dispatch = useDispatch();

	const onRowClick = (row: any) => {
		if (!!selectedMatches.find(match => match.id === row.id))
			setSelectedMatches(selectedMatches.filter(match => match.id !== row.id));
		else setSelectedMatches(selectedMatches.concat(row));
	};

	const onSelectAll = (data: any[]) => {
		if (getIsSelectAllActive(data, selectedMatches))
			setSelectedMatches(
				selectedMatches.filter(
					selectedMatch => !data.find(match => match.id === selectedMatch.id)
				)
			);
		else
			setSelectedMatches(
				selectedMatches.concat(
					data.filter(
						currentMatch =>
							!selectedMatches.find(
								selectedMatch => selectedMatch.id === currentMatch.id
							)
					)
				)
			);
	};

	const onSubmit = async (payload: TOnSubmit) => {
		setIsSubmitLoading(true);
		try {
			await MatchesService.generateNewStatement({
				...payload,
				competitionId: selectedChampionshipGroup.id,
				selected_matches_ids: selectedMatches.map(match => match.id),
			});
			dispatch(push(EPath.statements));
			Toast.success('Comunicato generato correttamente');
		} catch (err) {
			console.log({ err });
			Toast.error('Impossibile generare il comunicato');
		} finally {
			setIsSubmitLoading(false);
		}
	};

	const onRemoveAllSelection = () => {
		setSelectedMatches([]);
	};

	return (
		<GraphQLDataProvider
			id="statement-generation"
			query={query}
			defaultSort={'competition.step'}
			fixedFilters={{
				competitionId: selectedChampionshipGroup.id,
				roundId: selectedRoundGroup.id,
				stepId: selectedStepGroup.id,
			}}
			limit={100}>
			<GraphQLDataContext.Consumer>
				{context => (
					<>
						<View
							selectedMatches={selectedMatches}
							onSelectAll={onSelectAll}
							isSelectAllActive={getIsSelectAllActive(
								getMatchesFromContextData(context.data),
								selectedMatches
							)}
							isSubmitLoading={isSubmitLoading}
							onSubmit={onSubmit}
							onRemoveAllSelection={onRemoveAllSelection}
							error={context.error}
							data={getMatchesFromContextData(context.data)}
							onRowClick={onRowClick}
							loading={context.loading}
							onFilter={filters => {
								context.onFilter && context.onFilter(filters);
								if (isCompetitionFilterChanged(filters)) setSelectedMatches([]);
							}}
							filters={context.filters}
							onSearch={context.onSearch}
							onSort={context.onSort}
							pagination={context.pagination}
						/>
					</>
				)}
			</GraphQLDataContext.Consumer>
		</GraphQLDataProvider>
	);
};

export default Container;

const query = gql`
	query GetMatches($filters: MatchFilters!, $opts: MatchesListOpts) {
		getMatches(filters: $filters, opts: $opts) {
			id
			date
			time
			step
			home {
				id
				club {
					name
					logo
				}
			}
			away {
				id
				club {
					name
					logo
				}
			}
			result {
				home
				away
			}
		}
	}
`;
