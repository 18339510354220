import { ACTIONS, AuthState } from './types';

const initState: AuthState = {
	access_token: null,
	georgian_token: null,
	login_error: false,
	login_completed: false,
	user_permissions: {},
	client_id: null,
	profile: {},
};

export default function authReducer(state: AuthState = initState, action: any): AuthState {
	switch (action.type) {
		case ACTIONS.AFTER_AUTH0_LOGIN:
			return { ...state, login_error: false };

		case ACTIONS.LOGIN_SUCCESS:
			return {
				...state,
				georgian_token: action.georgian_token,
				access_token: action.token,
				profile: action.profile,
				login_completed: action.login_completed,
				client_id: action.client_id,
			};

		case ACTIONS.LOGIN_ERROR:
			return { ...state, login_error: action.error };

		case ACTIONS.SETPERMISSIONFEATURES:
			return { ...state, user_permissions: action.payload };

		default:
			return state;
	}
}
