import { Button } from '@gle/base-ui.buttons.button';
import Toast from '../../../../../components/Toast';
import { Match } from '../../../../../_types';
import { usePartialPrint } from '../hooks/usePartialPrint';

type TProps = { match: Match; isVisible: boolean };

const PrintPartialStatementButton = ({ match, isVisible }: TProps) => {
	const { getUrl: getUrlPartialPrint, loading: isPartialPrintLoading } = usePartialPrint();

	const onPrintPartialStatement = async () => {
		try {
			const url = await getUrlPartialPrint(match.id);
			if (url) window.open(url, '_blank');
		} catch (e) {
			Toast.error('Si è verificato un errore');
		}
	};

	if (!isVisible) return <></>;
	return (
		<div>
			<Button
				level={'secondary'}
				size={'s'}
				onClick={onPrintPartialStatement}
				isLoading={isPartialPrintLoading}>
				<i className="las la-print"></i>
				{'Stampa'}
			</Button>
		</div>
	);
};

export { PrintPartialStatementButton };
