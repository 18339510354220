import React, { ReactChildren, useContext, useEffect, useState } from 'react';
import { Group } from '../redux/Championship/reducer';
import { useGroup } from './GroupProvider';
import { Loader } from '@gle/base-ui.loader';

const EmptyGroup = { id: '', subGroups: [], label: '', type: '' };

const FiltersContext = React.createContext<{
	selectedChampionshipGroup: Group;
	selectedRoundGroup: Group;
	selectedStepGroup: Group;
	onChampionshipChange: (id: string) => void;
	onRoundChange: (id: string) => void;
	onStepChange: (id: string) => void;
}>({
	selectedChampionshipGroup: EmptyGroup,
	selectedRoundGroup: EmptyGroup,
	selectedStepGroup: EmptyGroup,
	onChampionshipChange: () => {},
	onRoundChange: () => {},
	onStepChange: () => {},
});

export const FiltersProvider = ({ children }: { children: ReactChildren }) => {
	const { championshipGroups } = useGroup();

	const [selectedChampionshipGroup, setSelectedChampionshipGroup] = useState<Group>(EmptyGroup);
	const [selectedRoundGroup, setSelectedRoundGroup] = useState<Group>(EmptyGroup);
	const [selectedStepGroup, setSelectedStepGroup] = useState<Group>(EmptyGroup);

	useEffect(() => {
		if (championshipGroups.length) {
			onChampionshipChange(
				(
					championshipGroups.find(c => c.label.toLowerCase().includes('serie c')) ||
					championshipGroups[0]
				).id
			);
		}
	}, [championshipGroups]);

	const onChampionshipChange = (id: string) => {
		const championshipGroup = championshipGroups.find(g => g.id === id);
		if (championshipGroup) {
			setSelectedChampionshipGroup(championshipGroup);
			if (championshipGroup.subGroups?.length) {
				const roundGroup = championshipGroup.subGroups[0];
				setSelectedRoundGroup(roundGroup);
				if (roundGroup.subGroups?.length) {
					setSelectedStepGroup(roundGroup.subGroups[0]);
				}
			}
		}
	};

	const onRoundChange = (id: string) => {
		const roundGroup = selectedChampionshipGroup.subGroups?.find(g => g.id === id);
		if (roundGroup?.subGroups?.length) {
			const stepIndex = getStepIndex(roundGroup?.subGroups);
			setSelectedRoundGroup(roundGroup);
			setSelectedStepGroup(stepIndex);
		}
	};

	const onStepChange = (id: string) => {
		setSelectedStepGroup(selectedRoundGroup.subGroups?.find(g => g.id === id)!);
	};

	const getStepIndex = (stepGroups: Group[]) => {
		const stepIndex =
			selectedRoundGroup.subGroups?.findIndex(g => g.id === selectedStepGroup.id) || 0;
		return stepGroups.length > stepIndex ? stepGroups[stepIndex] : stepGroups[0];
	};

	return (
		<FiltersContext.Provider
			value={{
				selectedChampionshipGroup,
				selectedRoundGroup,
				selectedStepGroup,
				onChampionshipChange,
				onRoundChange,
				onStepChange,
			}}>
			{selectedChampionshipGroup && selectedRoundGroup && selectedStepGroup ? (
				children
			) : (
				<div className="flex items-center justify-center full-height">
					<Loader size="l" />
				</div>
			)}
		</FiltersContext.Provider>
	);
};

export const useFilters = () => {
	const context = useContext(FiltersContext);

	if (!context) {
		throw new Error('useFilters must be used within FiltersProvider');
	}

	return context;
};
