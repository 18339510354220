import styled from 'styled-components';
import { useState } from 'react';

type TProps = {
	onChange: (ev: string | undefined) => void;
	placeholder?: string;
	value?: string;
	width?: number;
};

let timeout: any;

const Styled = styled.div`
	label {
		display: block;
		color: #777;
		font-size: var(--s-size);
		margin-bottom: 2px;
		font-weight: var(--font-weight-medium);
	}
	input {
		width: 100%;
		padding: 0 7px;
		border: 1px solid var(--gray-lightest-color);
		border-radius: 4px;
		appearance: none;
		outline: none;
		background: transparent;
		height: 35px;
		font-size: var(--m-size);
	}
`;

const Search = ({ ...props }: TProps) => {
	const [value, setValue] = useState<string | undefined>(props.value);
	const onType = (value: string) => {
		setValue(value);
		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			if (value.length >= 3 || value.length == 0) {
				if (!value) {
					props.onChange(undefined);
				} else {
					props.onChange(value);
				}
			}
		}, 500);
	};

	return (
		<Styled>
			<input
				className="gle-input"
				id="search"
				onChange={ev => onType(ev.target.value)}
				style={{ width: props.width || 350 }}
				placeholder={props.placeholder || 'Cerca...'}
				value={value}
			/>
		</Styled>
	);
};

export default Search;
