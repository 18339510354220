import { ERole } from '@golee/gle-types';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RoleWrapper } from '../components/RoleWrapper';
import { MatchPage } from '../pages/matches/Detail';
import { Matches } from '../pages/matches/List';
import { SearchPage } from '../pages/curriculum';
import { SyncPage } from '../pages/sync';
import { StatementsGeneration } from '../pages/statements/Generation';
import { Statements } from '../pages/statements/List';
import { EPath } from './types/path.enum';

const AppRouter = ({ history }: any) => {
	return (
		<>
			<ConnectedRouter history={history}>
				<Switch>
					<Redirect exact from="/" to={EPath.matches} />

					<Route path={EPath.matches} exact component={Matches} />

					<Route
						path={`${EPath.matches}/:id`}
						component={(props: any) => (
							<MatchPage
								{...props}
								breadcrumbs={[
									{
										path: EPath.matches,
										label: 'Partite',
									},
								]}
							/>
						)}
					/>

					<RoleWrapper allowedRoles={[ERole.judge]}>
						<>
							<Route
								path={EPath.statements}
								render={params => (
									<>
										<Route
											exact
											path={`${params.match.url}/`}
											component={Statements}
										/>
										<Route
											path={`${params.match.url}/generate`}
											component={StatementsGeneration}
										/>
									</>
								)}
							/>

							<Route
								path={EPath.curriculum}
								render={params => (
									<>
										<Route
											exact
											path={`${params.match.url}/`}
											component={SearchPage}
										/>
									</>
								)}
							/>

							<Route exact path={EPath.sync} component={SyncPage} />
						</>
					</RoleWrapper>
				</Switch>
			</ConnectedRouter>
		</>
	);
};

export default AppRouter;
