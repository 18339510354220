import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import Toast from '../../../components/Toast';
import { EPath } from '../../../router/types/path.enum';
import { Document } from '../../../services';
import MatchDetail from './MatchDetailContext/MatchDetail';
import { Loader } from '@gle/base-ui.loader';
import { ApolloError } from '@apollo/client';
import { Button } from '@gle/base-ui.buttons.button';

export type TMatchModules = {
	referee?: Document[];
	league?: Document[];
	federation?: Document[];
};

const Page = ({ ...props }: { breadcrumbs: Breadcrumb[] }) => {
	const dispatch = useDispatch();
	const { id } = useParams<any>();

	const onError = (error?: ApolloError) => {
		console.error(error);
		Toast.error('Partita non trovata');
		dispatch(push(EPath.matches));
	};

	return (
		<MatchDetail id={id} onError={onError}>
			{({ match, fines, reloadFines, loading }) =>
				loading || !match ? (
					<div className="flex items-center justify-center">
						<Loader />
					</div>
				) : (
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '25% 75%',
						}}>
						<div className={'flex flex-column gap-10'}>
							<GoBackButton text={'< Torna a lista partite'} />
							<MatchDetail.MatchResult match={match} />
							<Divider.Top />
							<MatchDetail.Detail match={match} />
							<Divider.Top />
							<MatchDetail.FinesSummary
								match={match}
								fines={fines}
								onChangeFines={reloadFines}
							/>
							<Divider.Top />
							<MatchDetail.MatchModules match={match} onModuleChanged={reloadFines} />
						</div>
						<div className={'flex'} style={{ marginLeft: 20 }}>
							<Divider.Left />
							<div style={{ width: '100%', marginLeft: 20 }}>
								<MatchDetail.AthletesFinesList
									match={match}
									fines={fines}
									onChangeFines={reloadFines}
								/>
							</div>
						</div>
					</div>
				)
			}
		</MatchDetail>
	);
};

export default Page;

const Divider = {
	Top: () => (
		<div
			style={{
				borderTop: '1px solid #e8e8e8',
			}}
		/>
	),
	Left: () => (
		<div
			style={{
				borderLeft: '1px solid #e8e8e8',
			}}
		/>
	),
};

const GoBackButton = ({ text }: { text?: string }) => {
	const history = useHistory();
	return (
		<div>
			<Button size="s" level="tertiary" color="gray" onClick={() => history.goBack()}>
				{text || '< Indietro'}
			</Button>
		</div>
	);
};
