import Select from 'react-select';

type Props = {
	placeholder?: string;
	options?: { value: any; label: string }[];
	onChange?: (e?: any) => void;
	onBlur?: (e?: any) => void;
	onFocus?: (e?: any) => void;
	value?: any;
	disabled?: boolean;
	isClearable?: boolean;
	name?: string;
	minWidth?: React.CSSProperties['minWidth'];
	key?: string;
};

const Component = ({ ...props }: Props) => {
	return (
		<Select
			key={props.key}
			className="select"
			id={props.name}
			isDisabled={props.disabled}
			classNamePrefix="select"
			noOptionsMessage={() => 'Nessun risultato'}
			placeholder={props.placeholder}
			menuPlacement={'auto'}
			styles={{
				container: provided => ({
					...provided,
					minWidth: props.minWidth || '200px',
				}),
				control: provided => ({
					...provided,
					borderColor: 'var(--gray-lightest-color)',
				}),
				input: provided => ({
					...provided,
					fontSize: 'var(--m-size)',
				}),
				placeholder: provided => ({
					...provided,
					fontSize: 'var(--m-size)',
				}),
				option: provided => ({
					...provided,
					fontSize: 'var(--m-size)',
				}),
				valueContainer: provided => ({
					...provided,
					fontSize: 'var(--m-size)',
				}),
			}}
			value={
				props.value
					? {
							value: props.value,
							label: props.options?.find(t => t.value === props.value)?.label,
					  }
					: undefined
			}
			options={props.options}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			isClearable={props.isClearable}
			onChange={props.onChange}
			name={props.name}
		/>
	);
};

export default Component;
