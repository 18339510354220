import React from 'react';
import { RestDataProviderContext, GraphQLDataProviderContext } from '../types';

export const RestDataContext = React.createContext<RestDataProviderContext>({
	data: [],
	loading: false,
	error: false,
});

export const GraphQLDataContext = React.createContext<GraphQLDataProviderContext>({
	data: undefined,
	loading: false,
	error: false,
});
