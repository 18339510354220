import { Group } from '../../redux/Championship/reducer';
import { Select } from '../Inputs';

const RoundSelector = ({
	onChange,
	isClearable,
	value,
	roundGroups,
}: {
	onChange: (id?: string) => void;
	value: string;
	isClearable?: boolean;
	roundGroups?: Group[];
}) => {
	const getOptions = () => roundGroups?.map(t => ({ value: t.id, label: t.label })) || [];

	return (
		<Select
			placeholder="Girone"
			onChange={e => onChange(e?.value)}
			value={value}
			options={getOptions()}
			isClearable={isClearable}
		/>
	);
};

export { RoundSelector };
