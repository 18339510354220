export enum MatchGroupType {
	lega = 'lega',
	competition = 'competition',
	round = 'round',
	step = 'step',
}

export type Match = {
	id: string;
	date?: string;
	time?: string;
	result?: {
		home: number;
		away: number;
	};
	groups: {
		type: MatchGroupType;
		label: string;
	}[];
	home: {
		id: number;
		club: {
			id: number;
			organizationId: string;
			name: string;
			logo?: string;
		};
	};
	away: {
		id: number;
		club: {
			id: number;
			organizationId: string;
			name: string;
			logo?: string;
		};
	};
	reports?: {
		federation?: string[];
		league?: string[];
		referee?: string[];
	};
	judges: any;
};

export type TReportType = 'referee' | 'league' | 'federation';
export type TDocType = 'referee_module' | 'federation_module' | 'league_module';
