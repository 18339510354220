import { useState } from 'react';
import { Button } from '@gle/base-ui.buttons.button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CreatableFormProps } from './types';
import { PeopleService } from '../../services';
import Toast from '../Toast';
import { FormField } from '../Inputs';
import { InputPhoneField } from '../Inputs/InputPhone';
import { isValidPhone } from '@gle/base-ui.inputs.input-phone';
import { EProfileRole, IProfile } from '@golee/people-sdk';
import { EUserRole } from '@golee/guardian-sdk';

const Component = ({ ...props }: CreatableFormProps<IProfile> & { role: EProfileRole }) => {
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const schema = Yup.object().shape({
		name: Yup.string().required('Dato obbligatorio'),
		surname: Yup.string().required('Dato obbligatorio'),
		phone: Yup.string()
			.test('valid-phone', 'Numero non valido', value =>
				value ? isValidPhone(value!) : true
			)
			.required('Dato obbligatorio'),
	});

	const onSubmit = async (values: any) => {
		setIsSaving(true);

		try {
			const profile = await PeopleService.createProfile({
				name: values.name,
				surname: values.surname,
				phone: values.phone,
				role: props.role,
			});

			const { id } = await PeopleService.createInvite(profile._id, {
				label: values.name.concat(' ', values.surname || ''),
				profileRole: props.role,
				userRole: props.role as any as EUserRole, // FIXME there should be a mapping between profileRole and userRole
			});

			Toast.info(`${values.name} è stato invitato con un sms a riscattare il suo profilo`);

			props.onCreateCompleted({
				...values,
				role: props.role,
				_id: profile._id,
			});
		} catch (err) {
			console.log(err);
			Toast.error("Errore nella creazione o nell'invito del profilo");
		} finally {
			setIsSaving(false);
		}
	};

	const splitName = (name: string): { name: string; surname: string } => {
		const nameArray = name.split(' ');
		return { name: nameArray.shift() || '', surname: nameArray.join(' ') };
	};

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{
					name: splitName(props.name || '').name,
					surname: splitName(props.name || '').surname,
					phone: '',
				}}
				onError={(err: any) => console.log(err)}
				validationSchema={schema}
				onSubmit={onSubmit}>
				{formContext => {
					return (
						<Form>
							<div className="flex gap-20 flex-column">
								<div className="flex gap-20 flex-row justify-between">
									<FormField name="name" type="text" label="Nome" />
									<FormField name="surname" type="text" label="Cognome" />
								</div>

								<InputPhoneField name="phone" label="Telefono" />
							</div>

							<div className="flex justify-end gap-20" style={{ marginTop: 20 }}>
								<Button
									level="tertiary"
									onClick={props.onCancel}
									disabled={isSaving}>
									Annulla
								</Button>
								<Button isLoading={isSaving} type="submit">
									Crea
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default Component;
