import { MatchResult, Detail, FinesSummary, MatchModules } from './components';
import { MatchDetailContext, MatchDetailProvider } from './hooks/useMatchDetailProvider';
import { ReactNode } from 'react';
import { Index } from './components/persons-club-list';
import { ApolloError } from '@apollo/client';
import { PrintPartialStatementButton } from './components/PrintPartialStatementButton';
import { FinesContext, FinesProvider } from './hooks/useFines';
import { Fine, Match } from '../../../../_types';

const MatchDetail = (props: {
	id: string;
	children: (context: {
		match?: Match;
		fines: Fine[];
		reloadFines: () => void;
		loading: boolean;
	}) => ReactNode;
	onError: (error?: ApolloError) => void;
}) => {
	return (
		<MatchDetailProvider id={props.id} onError={props.onError}>
			<FinesProvider matchId={props.id} onError={props.onError}>
				<MatchDetailContext.Consumer>
					{matchDetailContext => (
						<FinesContext.Consumer>
							{finesContext => {
								return props.children({
									match: matchDetailContext.match,
									fines: finesContext.fines,
									reloadFines: finesContext.reloadFines,
									loading: matchDetailContext.loading,
								});
							}}
						</FinesContext.Consumer>
					)}
				</MatchDetailContext.Consumer>
			</FinesProvider>
		</MatchDetailProvider>
	);
};

MatchDetail.MatchResult = MatchResult;
MatchDetail.Detail = Detail;
MatchDetail.FinesSummary = FinesSummary;
MatchDetail.MatchModules = MatchModules;
MatchDetail.AthletesFinesList = Index;
MatchDetail.PrintPartialStatement = PrintPartialStatementButton;

export default MatchDetail;
