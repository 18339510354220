import { useRef } from 'react';
import styled from 'styled-components';

export type CheckBoxProps = {
	/**
	 * Name of the field.
	 */
	name: string;

	/**
	 * Label of the field.
	 */
	label: string | React.ReactNode;

	/**
	 * On change trigger.
	 */
	onChange: (value: boolean) => void;

	/**
	 * Value of the field
	 */
	value?: boolean;

	/**
	 * Type of render.
	 */
	render?: 'slider' | 'classic' | 'square';
};

const Slider = styled.span`
	position: relative;
	display: flex;

	input {
		margin: 1px 0 0 0;
		cursor: pointer;
		opacity: 0;
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0px;
		width: 32px;
		height: 20px;

		&:checked + label {
			&:before {
				content: '';
				background-color: var(--primary-color);
				padding-left: 6px;
			}

			&:after {
				left: 13px;
			}
		}
	}

	label {
		font-size: var(--s-size);
		color: var(--gray-light-color);

		strong {
			font-weight: var(--font-weight-bold);
		}

		cursor: pointer;
		position: relative;
		padding-left: 40px;
		line-height: 20px;

		&:before,
		&:after {
			position: absolute;
			border-radius: 10px;
			transition: background-color 0.3s, left 0.3s;
		}

		&:before {
			content: '';
			color: #fff;
			box-sizing: border-box;
			padding-left: 23px;
			font-size: 12px;
			line-height: 20px;
			background-color: #dedede;
			left: 0px;
			top: 0px;
			height: 20px;
			width: 32px;
			border-radius: 10px;
		}

		&:after {
			content: '';
			background: #fff;
			left: 1px;
			top: 1px;
			height: 18px;
			width: 18px;
		}
	}
`;

const Classic = styled.label`
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	font-size: var(--s-size);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: var(--gray-light-color);
	height: 20px;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 5px;
		background-color: #eee;
	}

	/* On mouse-over, add a grey background color */

	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a primary background */

	input:checked ~ .checkmark {
		background-color: var(--primary-color);
	}

	/* Create the checkmark/indicator (hidden when not checked) */

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */

	input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */

	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const Square = styled.label`
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	font-size: var(--s-size);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 20px;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 5px;
		background-color: var(--gray-lighter-color);
	}

	/* On mouse-over, add a grey background color */

	&:hover input ~ .checkmark {
		background-color: var(--gray-light-color);
	}

	/* When the checkbox is checked, add a primary background */

	input:checked ~ .checkmark {
		//background-color: var(--primary-color);
	}

	/* Create the checkmark/indicator (hidden when not checked) */

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */

	input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */

	.checkmark:after {
		left: 4px;
		top: 4px;
		background-color: var(--primary-lighter-color);
		height: 12px;
		width: 12px;
		border-radius: 20px;
	}
`;

const Component = ({ render = 'slider', ...props }: CheckBoxProps) => {
	const ref: any = useRef();

	const renderSlider = () => {
		return (
			<Slider>
				<input
					type="checkbox"
					value={props.value ? 'true' : 'false'}
					checked={props.value}
					ref={ref}
					onChange={ev => {
						props.onChange(ev.target.checked);
					}}
				/>
				<label
					htmlFor={props.name}
					onClick={() => {
						ref.current?.click?.();
					}}>
					{props.label}
				</label>
			</Slider>
		);
	};

	const renderClassic = () => {
		return (
			<Classic
				htmlFor={props.name}
				onClick={() => {
					ref.current?.click?.();
				}}>
				{props.label}
				<input
					type="checkbox"
					value={props.value ? 'true' : 'false'}
					checked={props.value}
					ref={ref}
					onChange={ev => {
						props.onChange(ev.target.checked);
					}}
				/>
				<span className="checkmark"></span>
			</Classic>
		);
	};

	const renderSquare = () => {
		return (
			<Square
				htmlFor={props.name}
				onClick={() => {
					ref.current?.click?.();
				}}>
				{props.label}
				<input
					type="checkbox"
					value={props.value ? 'true' : 'false'}
					checked={props.value}
					ref={ref}
					onChange={ev => {
						props.onChange(ev.target.checked);
					}}
				/>
				<span className="checkmark"></span>
			</Square>
		);
	};

	switch (render) {
		case 'slider':
			return <>{renderSlider()}</>;
		case 'classic':
			return <>{renderClassic()}</>;
		case 'square':
			return <>{renderSquare()}</>;
		default:
			return <>{renderClassic()}</>;
	}
};

export default Component;
