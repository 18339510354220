import { InputTime, InputTimeProps } from '@gle/base-ui.inputs.input-time';
import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

type Props = FieldConfig<any> & Omit<InputTimeProps, 'onChange' | 'onBlur' | 'onFocus'>;

const Wrapper = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}
		input {
			border-color: var(--danger-color);
		}
	}
`;

const Field = ({ ...props }: Props) => {
	const [field, meta, helpers] = useField(props);

	return (
		<Wrapper className={`${meta.touched && meta.error ? 'error' : ''} `}>
			<InputTime {...field} {...props} onChange={value => helpers.setValue(value, true)} />
			<ErrorMessage name={props.name} component="div" className="form-field-error" />
		</Wrapper>
	);
};

export default Field;
