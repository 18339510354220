import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@gle/base-ui.loader';
import { Theme } from '@gle/base-ui.theme-provider';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authAction from '../redux/Auth/actions';
import { store } from '../redux/store';
import { AppState } from '../redux/types';
import { GroupProvider } from './GroupProvider';
import { FiltersProvider } from './FiltersProvider';
import { OrganizationsProvider } from './OrganizationsProvider';

const Setup = ({ children }: any) => {
	const {
		isLoading,
		logout,
		isAuthenticated,
		loginWithRedirect,
		user,
		getIdTokenClaims,
		getAccessTokenSilently,
	} = useAuth0();

	const dispatch = useDispatch();

	const { access_token, login_error, login_completed } = useSelector(
		(state: AppState) => state.Auth
	);

	const handleAuth = async () => {
		if (!isLoading) {
			if (!isAuthenticated) {
				loginWithRedirect();
			} else {
				let token = await getAccessTokenSilently();
				const _ = await getIdTokenClaims();
				let georgian_token = _ && _['__raw'];

				dispatch(authAction.afterAuth0({ user, georgian_token, token }, true));
			}
		}
	};

	useEffect(() => {
		handleAuth();
	}, [isLoading]);

	useEffect(() => {
		if (login_error === 'SAGA_ERROR') {
			logout({ returnTo: window.location.origin });
		}
	}, [login_error]);

	const [client, setClient] = useState<ApolloClient<any> | undefined>(undefined);

	useEffect(() => {
		if (login_completed && access_token) {
			const _ = new ApolloClient({
				uri: window._env_.REACT_APP_API_URL_GQL_GATEWAY,
				cache: new InMemoryCache(),
				defaultOptions: {
					watchQuery: {
						fetchPolicy: 'no-cache',
					},
					query: {
						fetchPolicy: 'no-cache',
					},
				},
				headers: {
					Authorization: 'Bearer ' + store.getState().Auth.access_token,
					'x-gle-role': store.getState().Auth.profile.role.role_id,
				},
			});

			setClient(_);
		}
	}, [login_completed]);

	return (
		<Theme className="legapro">
			{!isLoading && login_completed && client ? (
				<ApolloProvider client={client}>
					<GroupProvider>
						<OrganizationsProvider>
							<FiltersProvider>{children}</FiltersProvider>
						</OrganizationsProvider>
					</GroupProvider>
				</ApolloProvider>
			) : (
				<div className="flex items-center justify-center full-height">
					<Loader size="l" />
				</div>
			)}
		</Theme>
	);
};

export default Setup;
