import { toast as toastify } from 'react-toastify';

export enum ToastType {
	ERROR = 'error',
	SUCCESS = 'success',
	WARNING = 'warning',
	INFO = 'info',
	DEFAULT = 'default',
}

const Toast = ({ ...props }: any) => {
	return (
		<>
			<p style={{ margin: 0 }}>{props.message}</p>
		</>
	);
};

export const render = (type: ToastType, message: string, title?: string) => {
	switch (type) {
		case ToastType.ERROR:
			toastify.error(<Toast type={type} title={title || 'Errore'} message={message} />);
			break;

		case ToastType.SUCCESS:
			toastify.success(<Toast type={type} title={title || 'Successo'} message={message} />);
			break;

		case ToastType.WARNING:
			toastify.warning(<Toast type={type} title={title || 'Attenzione'} message={message} />);
			break;

		case ToastType.INFO:
			toastify.info(<Toast type={type} title={title || 'Informazione'} message={message} />);
			break;

		case ToastType.DEFAULT:
			toastify(<Toast type={type} title={title || 'Attenzione'} message={message} />);
			break;
	}
};
