import Layout from './Layout';
import AppRouter from './router';
import { history } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PathUrl } from './classes/PathUrl/PathUrl.class';
import { Conan } from './modules/@tracking';

const App = () => {
	useEffect(() => {
		history.listen(location => {
			const path_url = new PathUrl(location.pathname + location.hash);
			if (!!path_url.area) {
				Conan.trackEvent('Page view', path_url);
			}
		});
	}, []);

	return (
		<>
			<Layout>
				<AppRouter history={history} />
			</Layout>
			<ToastContainer />
		</>
	);
};

export default App;
