import api from './api';
import axios from './interceptor';

const service = {
	compileAndSave: async (payload: {
		data: any;
		templateAsBase64: string;
		output?: string;
		file_name?: string;
	}): Promise<{ url: string }> => {
		try {
			return (await axios.post(api.compileAndSave(), payload)).data;
		} catch (err) {
			throw err;
		}
	},
};

export default service;
