import { Button } from '@gle/base-ui.buttons.button';
import { CircleButton } from '@gle/base-ui.buttons.circle-button';
import { Tooltip } from '@gle/base-ui.tooltip';
import { useEffect, useRef, useState } from 'react';
import { PepaInterceptor } from '../../../services';
import Toast from '../../Toast';
import { Text } from '@gle/base-ui.typography.text';

type Props = {
	/**
	 * Index of the already uploaded document.
	 */
	index?: string;

	/**
	 * Name of the already uploaded document.
	 */
	name: string;

	/**
	 * Url of the already uploaded document.
	 */
	url?: string;

	/**
	 * Override this function to retrieve the document url on open button click
	 */
	getDocumentUrlOnClick?: () => Promise<string>;

	/**
	 * Callback on remove file.
	 */
	onRemove?: () => void;

	/**
	 * Callback on edit file.
	 */
	onReplace?: (file: File, url: string) => void;
};

const Component = ({ ...props }: Props) => {
	const [url, setUrl] = useState<string | undefined>(props.url);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isGettingUrl, setIsGettingUrl] = useState<boolean>(false);

	useEffect(() => {
		setUrl(props.url);
	}, [props.url]);

	const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			onFileUpload(event.target.files[0]);
		}
	};

	const onFileUpload = (file: File) => {
		setIsUploading(true);
		uploadFile(file);
	};

	const onOpenClick = () => {
		return async () => {
			if (props.getDocumentUrlOnClick && !url) {
				setIsGettingUrl(true);
				try {
					const retrieved_url = await props.getDocumentUrlOnClick();
					setUrl(retrieved_url);
					window.open(retrieved_url, '_blank');
				} catch (err) {
					console.error(err);
					Toast.error('Impossibile recuperare il link del documento.');
				} finally {
					setIsGettingUrl(false);
				}
			} else {
				window.open(url, '_blank');
			}
		};
	};

	const uploadFile = async (file: File) => {
		try {
			let formData = new FormData();
			formData.append('file', file);
			const { data } = await PepaInterceptor.post(
				`${window._env_.REACT_APP_API_URL_PEPA_SERVICE}files`,
				formData
			);
			props.onReplace && props.onReplace(file, data.public_url);

			inputRef.current.value = '';
		} catch (err) {
			console.error(err);
			Toast.error('Impossibile caricare il file.');
		} finally {
			setIsUploading(false);
		}
	};

	const openButton = () => {
		return (
			<Button onClick={onOpenClick()} isLoading={isGettingUrl} disabled={isUploading}>
				Apri <i className="las la-external-link-alt"></i>
			</Button>
		);
	};

	const editButton = () => {
		return (
			<Tooltip content="Cambia">
				<CircleButton
					isLoading={isUploading}
					onClick={() => {
						inputRef.current.click();
					}}>
					<i className="las la-exchange-alt"></i>
				</CircleButton>
			</Tooltip>
		);
	};

	const removeButton = () => {
		if (!props.onRemove) {
			return null;
		}

		return (
			<Tooltip content="Rimuovi">
				<CircleButton
					disabled={isUploading}
					color="danger"
					onClick={() => {
						inputRef.current.value = '';
						setUrl(undefined);
						props.onRemove && props.onRemove();
					}}>
					<i className="las la-trash"></i>
				</CircleButton>
			</Tooltip>
		);
	};

	const inputRef: any = useRef();

	return (
		<div className="flex items-center justify-between" style={{ gap: 10 }}>
			<div className={'flex items-center'} style={{ gap: 10 }}>
				{props.index && (
					<div
						className="flex items-center justify-center"
						style={{
							border: '1px solid #0e5a9a',
							height: 10,
							width: 10,
							borderRadius: '25%',
							padding: 5,
						}}>
						<Text size={'xs'} color={'primary'}>
							{props.index}
						</Text>
					</div>
				)}

				<Text
					size={'xs'}
					color={'primary'}
					style={{
						width: 180,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
					{props.name}
				</Text>
			</div>
			<>
				<input
					name="file-upload"
					accept="application/pdf"
					type="file"
					onChange={onFileChange}
					ref={inputRef}
					style={{ display: 'none', visibility: 'hidden' }}
				/>

				<div className={'flex'} style={{ gap: 10 }}>
					{openButton()}
					{editButton()}
					{removeButton()}
				</div>
			</>
		</div>
	);
};

export default Component;
