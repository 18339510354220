import { CloseButton } from '@gle/base-ui.buttons.close-button';
import { Theme } from '@gle/base-ui.theme-provider';
import { Heading } from '@gle/base-ui.typography.heading';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100vh;
	width: 100%;
	z-index: 999;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: rgba(0, 0, 0, 0.2);

	.modal {
		height: auto;
		border-radius: 5px;
		width: 500px;
		max-width: 75%;
		background-color: #fff;
		background-color: white;
		padding: 30px;
		box-shadow: -10px 0px 20px 0px rgb(0 0 0 / 10%);
		position: relative;
		margin: 30px auto;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
`;

type TProps = {
	onClose: () => void;
	closeOnClickOutside?: boolean;
	children: React.ReactChild;
	title: string;
};

const Modal = ({ onClose, children, title, closeOnClickOutside = true }: TProps) => {
	const closeModal = () => {
		onClose();
	};

	const onClickOutside = (e: any) => {
		e.stopPropagation();
		if (closeOnClickOutside) {
			closeModal();
		}
	};

	const onClickCloseIcon = (e: any) => {
		e.stopPropagation();
		closeModal();
	};

	return createPortal(
		<Wrapper onClick={onClickOutside}>
			<Theme className="legapro">
				<div className="modal" onClick={e => e.stopPropagation()}>
					<div className="header">
						<Heading level={3}>{title}</Heading>
						<CloseButton className="close" onClick={onClickCloseIcon} />
					</div>

					<div style={{ marginTop: 30 }}>{children}</div>
				</div>
			</Theme>
		</Wrapper>,
		document.getElementById('modal-root')!
	);
};

export { Modal };
