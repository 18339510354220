import { ETeam, Fine, Person } from '../../../../../../_types';
import { Heading } from '@gle/base-ui.typography.heading';
import { Button } from '@gle/base-ui.buttons.button';
import Table from '../../../../../../components/Table/Table';
import { push } from 'connected-react-router';
import { EPath } from '../../../../../../router/types/path.enum';
import { useDispatch } from 'react-redux';
import { FineType } from '../../../../../../_types/enums';
import { FineIcon } from './FinesForm/FineIcon';
import { MatchesService } from '../../../../../../services';
import Toast from '../../../../../../components/Toast';
import { useEffect, useState } from 'react';
import { usePeople } from '../../../../../../hooks/usePeople';
import { getColumns } from './getColumns';
import {
	FineFormModalClub,
	FineFormModalClubEdit,
	FineFormModalPerson,
	FineFormModalPersonEdit,
} from './FineFormModal';
import { CheckBox } from '../../../../../../components/Inputs/CheckBox';
import { useFilters } from '../../../../../../providers/FiltersProvider';

type TProps = {
	club: { id: number; organizationId: string; name: string; logo?: string };
	ref_match: string;
	fines: Fine[];
	searchName?: string;
	searchSurname?: string;
	onChangeFines?: () => void;
};

export const ClubSection = ({
	club,
	ref_match,
	onChangeFines,
	searchName,
	searchSurname,
	fines,
}: TProps) => {
	const [currentPerson, setCurrentPerson] = useState<Person | undefined>();
	const [currentFine, setCurrentFine] = useState<Fine | undefined>();
	const [showPeopleOfAllCategory, setShowPeopleOfAllCategory] = useState<boolean>(false);
	const [isModalFineFormVisible, setIsModalFineFormVisible] = useState<boolean>(false);
	const { selectedChampionshipGroup } = useFilters();

	const { people, isLoading, onSearch } = usePeople();
	const dispatch = useDispatch();

	useEffect(() => {
		onSearch({ searchName, searchSurname, organizationId: club.organizationId });
	}, [searchName, searchSurname]);

	const onYellowCardAdded = async (person: Person) => {
		try {
			await MatchesService.createFine(ref_match, {
				entity: person.role,
				type: FineType.yellow_card,
				ref_club: Number(club.id),
				club_name: club.name,
				recipient: `${person.surname} ${person.name}`,
				ref_entity: person.goleeId,
				ref_match,
			});
			onChangeFines?.();
			Toast.success(`${person.surname} ${person.name} ammonito`);
		} catch (err) {
			Toast.error('Si è verificato un errore');
		}
	};

	const onCurriculumClick = (person: Person) => {
		dispatch(
			push(`${EPath.curriculum}?searchSurname=${person.surname}&searchName=${person.name}`, {
				person: person,
			})
		);
	};

	const onClickPersonFine = (person: Person, fine: Fine) => {
		setIsModalFineFormVisible(true);
		setCurrentPerson(person);
		setCurrentFine(fine);
	};

	const onAmendClicked = (person: Person) => {
		setIsModalFineFormVisible(true);
		setCurrentPerson(person);
	};

	const clearState = () => {
		setIsModalFineFormVisible(false);
		setCurrentPerson(undefined);
		setCurrentFine(undefined);
	};

	const onSubmitFineForm = () => {
		clearState();
		onChangeFines?.();
	};

	function renderFineForm() {
		if (isModalFineFormVisible) {
			const commonProps = {
				onClose: clearState,
				onSubmit: onSubmitFineForm,
				refMatch: ref_match,
				club: club,
			};
			if (currentPerson && currentFine)
				return (
					<FineFormModalPersonEdit
						{...commonProps}
						fine={currentFine}
						person={currentPerson}
					/>
				);
			if (currentPerson && !currentFine)
				return <FineFormModalPerson {...commonProps} person={currentPerson} />;
			if (!currentPerson && currentFine)
				return <FineFormModalClubEdit {...commonProps} fine={currentFine} />;
			if (!currentPerson && !currentFine) return <FineFormModalClub {...commonProps} />;
		}
	}

	return (
		<>
			{people.length ? (
				<>
					<div className={'flex justify-between items-center'}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
							<img src={club.logo} style={{ height: 30 }} alt={''} />
							<Heading level={4}>{club.name}</Heading>
							{renderClubFines(
								club,
								fine => {
									setIsModalFineFormVisible(true);
									setCurrentFine(fine);
								},
								fines
							)}
							<Button
								color="danger"
								level="tertiary"
								size="s"
								onClick={() => {
									setIsModalFineFormVisible(true);
								}}>
								Sanziona
							</Button>
						</div>
						<CheckBox
							name={''}
							label={'Mostra tutte le categorie'}
							onChange={value => setShowPeopleOfAllCategory(value)}
						/>
					</div>
					<Table
						columns={getColumns(
							onCurriculumClick,
							onYellowCardAdded,
							onAmendClicked,
							onClickPersonFine,
							fines
						)}
						data={
							showPeopleOfAllCategory
								? people
								: filterPeopleByCategory(people, selectedChampionshipGroup.label)
						}
						isLoading={isLoading}
					/>
				</>
			) : (
				<></>
			)}

			{renderFineForm()}
		</>
	);
};

const renderClubFines = (
	club: { id: number; name: string },
	onClick: (fine: Fine) => void,
	fines?: Fine[]
) => {
	const _fines = getClubFines(club.id, fines);

	if (!_fines.length) {
		return;
	}

	return _fines.map(fine => <FineIcon fine={fine} onClick={() => onClick(fine)} />);
};

const getClubFines = (id: number | string, fines?: Fine[]) => {
	if (!fines || !fines?.length) {
		return [];
	}

	return fines.filter(fine => fine.entity_type === 'club' && fine.ref_club === id);
};

function filterPeopleByCategory(people: Person[], championshipLabel: string) {
	const mapTemplateToChampionships: { [key: string]: string[] } = {
		[ETeam.FIRST_TEAM]: [
			'Campionato Serie C',
			'Coppa Italia',
			'Play-off Campionato Serie C',
			'Play-out Campionato Serie C',
			'Supercoppa',
		],
		[ETeam.YOUTH_TEAM]: ['Primavera 3', 'Primavera 4'],
	};

	return people.filter(
		person =>
			person.role !== 'GIOCATORE' ||
			(person.team &&
				mapTemplateToChampionships[person.team] &&
				mapTemplateToChampionships[person.team].includes(championshipLabel))
	);
}
